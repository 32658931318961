import AboutTheArtistPageImage from '../assets/images/singlepage/artist2.jpg';

const AboutTheArtistPage = () => {

    return (
        <>          
            <div id='artist' className="about-the-artist-page-container">
                <div className="left-container">
                    <h2>MEET THE ARTIST</h2>
                    <h1>HARISHMI</h1>
                    <h2>FOUNDER</h2>

                    <p>I am a self taught passionated artist who has been a part of the henna industry since 2015. 
                        What started as a hobby became a part time profession alongside my education.
                        Through the years I've specialized my skills in both traditional and modern henna designs, 
                        which has led me to find and develop my own creative style and techniques. 
                        <br/>
                        <br/>
                        For me henna is not only a unique art form, 
                        but a way of connecting with new people and to bring their vision alive, with a particular focus on brides and weddings. 
                        Therefore I aim to give a professional and personal experience to my clients where every single design is customized according to their vision.
                        <br/>
                        <br/>
                        I am always eager to learn, improvise and to work fast as an artist without compromising on design, prioritizing quality above all else. 
                        Quality includes that all my designs are made with 100% organic henna paste that 
                        I carefully prepare myself fresh prior to each booking which ensures the classic maroon stain. 
                        <br/>
                        <br/>
                        In line with my mission to visualize, create and innovate, I'm excited to make
                        <br/>
                         more memorable pieces of art through my passion of henna.
                    </p>
                </div>
                <div className="right-container">
                    <img className='about-page-image' src={AboutTheArtistPageImage} alt="" />
                </div>
            </div>
        </>
    );
  }
  
  export default AboutTheArtistPage;