import Logo from '../../assets/images/logo.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {

    return (
        <div className="footer">
            <div className="row">
                <div className="line"/>
                <img src={Logo} alt="" />
                <div className="line"/>
            </div>
            <div className="row">
                <ul>
                    <li>
                        <a href='https://www.facebook.com/hennabyharishmi/' target="_blank" className='social-media-button'>
                            <FacebookIcon/>
                        </a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com/hennabyharishmi/' target="_blank" className='social-media-button'>
                            <InstagramIcon/>
                        </a>
                    </li>
                    <li>
                        <a href='mailto: contact@hennabyharishmi.com' target="_blank" className='social-media-button'>
                            <EmailIcon/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
  }
  
  export default Footer;