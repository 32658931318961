import { Button } from "@mui/material";
import TopContainer from "../components/TopContainer";
import { darkButtonStyle } from "../styles/theme";

const ThankYou = () => {

  return (
    <div className="thank-you-container">
      <TopContainer  />
        <h1>THANK YOU!</h1>
        <br></br>
        <p>Your inquiry has been sent succesfully!</p>
        <p>Harishmi will get back to you within three business days, so be sure to check your email.</p>
        <p>Thank you for reaching out!</p>
        <Button sx={darkButtonStyle} onClick={() => window.location.href = "http://www.hennabyharishmi.com/"}>BACK TO HOME</Button>
    </div>
  );
}

export default ThankYou;