import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { lightButtonStyle } from '../styles/theme';
import { ImageList, ImageListItem } from '@mui/material';
import { InspirationList } from '../db';


type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  header: string;
  description: string;
  imageUrl?: string;
  inspirationImages?: InspirationList
}

const InfoModal = (props: Props) => {
  const { isOpen, setIsOpen, header, description, inspirationImages } = props;

  return (
      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
          <DialogContent dividers>
          <div className='dialog-container'>
            <h1>{header}</h1>
            <p>{description}</p>
            { inspirationImages &&
              <ImageList cols={3}>
              {inspirationImages.map((item) => (
                <ImageListItem key={item.imageUrl}>
                  <img src={item.imageUrl} alt=''/>
                </ImageListItem>
                ))}
              </ImageList>
              }
          </div>
          </DialogContent>
          <DialogActions>
            <Button sx={lightButtonStyle} onClick={(e) => setIsOpen(false)}>
                close
            </Button>
          </DialogActions>
      </Dialog>
  );
}
;

export default InfoModal;