import Service from './components/Service';
import BridalHennaImage from '../assets/images/singlepage/services/bridal_henna.jpg';
import GroupHennaImage from '../assets/images/singlepage/services/group_henna.jpg';
import CustomizedDecorImage from '../assets/images/singlepage/services/customized_decor.jpg';
import IndividualHennaImage from '../assets/images/singlepage/services/individual_henna.jpg';


const ServicesPage = () => {

    const services = [
        {
            text: 'BRIDAL HENNA',
            imageUrl: BridalHennaImage
        },
        {
            text: 'GROUP HENNA',
            imageUrl: GroupHennaImage
        },
        {
            text: 'CUSTOMIZED DECOR',
            imageUrl: CustomizedDecorImage
        },
        {
            text: 'INDIVIDUAL HENNA',
            imageUrl: IndividualHennaImage
        },
    ]

    return (
        <>          
            <div id='services' className="services-page-container">
                    <h1>SERVICES</h1>
                    <div className="services-page-content-container">
                        {
                            services.map(element => 
                                <Service 
                                    key={element.text} 
                                    imageUrl={element.imageUrl} 
                                    text={element.text} 
                                />
                            )
                        }
                    </div>
            </div>
        </>
    );
  }
  
  export default ServicesPage;