import { Button } from "@mui/material";
import { useState } from "react";
import AmountHands from "../../components/AmountHands";
import Grid from "../../components/Grid";
import LengthHands from "../../components/LengthHands";
import { leela_outer, radha_outer, maharani_outer, InspirationList, leelaDescription, radhaDescription, maharaniDescription } from "../../db";
import { removeOuter, updateBridal } from "../../features/order/orderSlice";
import { nextStep } from "../../features/order/stepsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { skipButtonStyle } from "../../styles/theme";
import { AMOUNT_HANDS, Image, LENGTH_HANDS } from "../../types";

function Outer() {
  const previouslySelectedInspirationImage = useAppSelector(state => state.order.bridal?.outer?.inspiration)
  const previouslySelectedLength = useAppSelector(state => state.order.bridal?.outer?.length) as LENGTH_HANDS;
  const [selectedCategory, setSelectedCategory] = useState<AMOUNT_HANDS>();
  const [selectedInspirationImage, setInspirationImage] = useState<Image | undefined>(previouslySelectedInspirationImage)
  const [inspirationImages, setInspirationImages] = useState<InspirationList>([]);
  const [isSelectingAmount, setIsSelectingAmount] = useState(false);
  const [isSelectingLength, setIsSelectingLength] = useState(false);
  const [description, setDescription] = useState('');
  const dispatch = useAppDispatch();
  const bridal = useAppSelector(state => state.order.bridal);

  const amountSelected = (selectedCategory: AMOUNT_HANDS) => {
    if (!selectedCategory) {
      return;
    }

    switch(selectedCategory) {
      case AMOUNT_HANDS.LEELA:
        setInspirationImages(leela_outer)
        setDescription(leelaDescription)
        break;
      case AMOUNT_HANDS.RADHA:
        setInspirationImages(radha_outer)
        setDescription(radhaDescription)
        break;
      case AMOUNT_HANDS.MAHARANI:
        setInspirationImages(maharani_outer)
        setDescription(maharaniDescription)
        break;
      default:
        console.error('Amount could not be found')
    }
    setSelectedCategory(selectedCategory);

    //Now select inspiration image from grid
    setIsSelectingAmount(true)
  }

  const hasSelectedInspirationImageCallback = (_inspiration: Image) => {
    if(!_inspiration) return

    setInspirationImage(_inspiration)
    setIsSelectingLength(true)
  }

  const next = (_length: LENGTH_HANDS) => {
    if(!selectedCategory || !selectedInspirationImage) return;

    dispatch(updateBridal(
      {
        ...bridal,
        outer: {
          amount: selectedCategory,
          inspiration: selectedInspirationImage,
          length: _length
        }
    }))
    dispatch(nextStep());
  }

    const skip = () => {
    setInspirationImage(undefined);
    dispatch(removeOuter());
    dispatch(nextStep())
  }

  return (
      <div className="inner-container">
        {
          selectedCategory &&
        <Grid 
          isGridOpen={isSelectingAmount}
          inspirationImages={inspirationImages} 
          selectedCategory={selectedCategory}
          closeGrid={() => setIsSelectingAmount(false)}
          hasSelectedInspirationImageCallback={(inspiration) => hasSelectedInspirationImageCallback(inspiration)}
          previouslySelectedInspirationImage={previouslySelectedInspirationImage}
          description={description}
          />
        }
          <LengthHands
            isLengthDialogOpen={isSelectingLength}
            closeLengthDialog={() => setIsSelectingLength(false)}
            hasSelectedLengthCallback={(selectedLength: LENGTH_HANDS) => next(selectedLength)}
            previouslySelectedLength={previouslySelectedLength}
           />
        <AmountHands amountSelected={(selectedAmount) => amountSelected(selectedAmount)} innerOrOuter='OUTER' />
        <Button style={skipButtonStyle} onClick={skip}>Skip outer hands design</Button>
      </div>
  );
}

export default Outer;