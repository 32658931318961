import { createTheme, SxProps } from '@mui/material/styles';

export const darkButtonStyle: SxProps = {
  color: '#B8977E',
  backgroundColor: 'black',
  border: '1px solid #B8977E',
  borderRadius: 10,
  fontFamily: 'Lato-regular',
  letterSpacing: 2,
  fontSize: 12,
  padding: '7px 16px',
  margin: '20px',

  ":disabled": {
    fontStyle: 'italic',
    color: '#b8977e85',
    border: 'none',

  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#DDB28DB0',
      light: '#DDB28DB0',
      dark: '#DDB28DB0'
    },
    secondary: {
       main: '#DDB28DB0'
    },
    text: {
      primary: '#000000',
      secondary: '#000000'
    },
  }
});

export const sendButtonStyle: SxProps = {
  color: 'black',
  backgroundColor: '#ddb28db0',
  borderRadius: 5,
  fontSize: 16,
  fontFamily: 'Lato-bold',
  padding: '5px 26px',

  ":disabled": {
    fontStyle: 'italic',
    color: '#ffecdb6e',
  },
  ":hover": {
    backgroundColor: '#ddb28db0',  }
}

export const lightButtonStyle: SxProps = {
  color: 'white',
  backgroundColor: '#ddb28db0',
  borderRadius: 2,
  height: 30,
  fontSize: 12,
  fontFamily: 'Lato-regular',
  padding: '0px 20px',

  ":disabled": {
    fontStyle: 'italic',
    color: '#ffecdb6e',
  },
  ":hover": {
    backgroundColor: '#ddb28db0',
  }
}

export const skipButtonStyle = {
  color: '#ddb28db0',
  backgroundColor: '#27170C',
  height: 30,
  fontSize: 12,
  fontFamily: 'Lato-regular',
  padding: '0px 20px',
  border: '1px solid #ddb28db0',
  margin: '20px'
}


export const stepperStyle: SxProps = {
  ":hover": {
    cursor: 'pointer'
  },
  '& .MuiStepLabel-label': {
    color: '#866d594d'
  },
  '& .MuiStepIcon-root': {
    color: '#866d594d'
  },
  '& .MuiStepLabel-root .Mui-completed': {
    color: '#ddb28db0', // circle color (COMPLETED)
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
    {
      color: '#ddb28db0', // Just text label (COMPLETED)
    },
  '& .MuiStepLabel-root .Mui-active': {
    color: '#ddb28db0', // circle color (ACTIVE)
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
    {
      color: '#ddb28db0', // Just text label (ACTIVE)
    },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: 'black', // circle's number (ACTIVE)
  },
}

export const textfieldStyle: SxProps = {
  width: "80%",
  
  "& .MuiFilledInput-root": { 
    backgroundColor: 'transparent'
  },
  "& .MuiFilledInput-root.Mui-focused": { 
    backgroundColor: 'transparent'
  },
  input: {
    textAlign: 'center', 
    color: 'black', 
    fontSize: 17,
    fontFamily: 'Lato-regular',
    letterSpacing: '3px'
  }
}