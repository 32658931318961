import { useState, useEffect } from "react";
import { useAppSelector } from "../hooks";
import { useParams } from "react-router-dom";
import TopContainer from "../components/TopContainer";
import { Alert, SxProps, TextField } from "@mui/material";
import { GROUP_CATEGORIES } from "../types";
import Simple from "../assets/images/group/simple.jpg";
import Classic from "../assets/images/group/classic.jpg";
import { Inquiry, setIsAuthenticated } from "../features/order/appSlice";
import Loader from "../components/Loader";
import { formatDate } from "../utils";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";

const PublicResume = () => {
  const inquiries = useAppSelector((state) => state.app.inquiries);
  const isAuthenticated = useAppSelector((state) => state.app.isAuthenticated);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [errorHappened, setErrorHappened] = useState(false);
  const dispatch = useDispatch();
  const auth = getAuth();

  const [inquiry, setInquiry] = useState<Inquiry>();

  useEffect(() => {
    onAuthStateChanged(auth, (data) => {
      dispatch(setIsAuthenticated(!!data));
    })
  }, [])

  useEffect(() => {
    setLoading(true);
    const foundInquiry = inquiries.find((inquiry) => inquiry.id === id);

    if (foundInquiry) {
      setInquiry(foundInquiry);
      setLoading(false);
    } else {
      setErrorHappened(true);
      setLoading(false);
    }
  }, [inquiries, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {errorHappened ? (
            <Alert sx={{ position: "sticky", top: 0, zIndex: 1 }} variant="filled" severity="error">
              Inquiry could not be found.
            </Alert>
          ) : (
            <>
              <TopContainer headerText="RESUME" backButtonRoute={isAuthenticated ? "/admin" : ""} />
              <div className="resume-outer-container">
                <div className="resume-inner-container">
                  <div className="service-container">
                    <h2>INFORMATION</h2>
                    <div className="resume-information-container">
                      <div className="row">
                        <h4>Name</h4>
                        <h5>{inquiry?.name}</h5>
                      </div>
                      <div className="row">
                        <h4>Email</h4>
                        <h5>{inquiry?.email}</h5>
                      </div>
                      <div className="row">
                        <h4>Henna date</h4>
                        <h5>{inquiry?.hennaDate && formatDate(inquiry?.hennaDate)}</h5>
                      </div>
                      <div className="row">
                        <h4>Wedding date</h4>
                        <h5>{inquiry?.weddingDate && formatDate(inquiry?.weddingDate)}</h5>
                      </div>
                      <div className="row">
                        <h4>Location</h4>
                        <h5>{inquiry?.location}</h5>
                      </div>
                    </div>
                  </div>
                  {(inquiry?.order.bridal?.inner?.amount ||
                    inquiry?.order.bridal?.outer?.amount ||
                    inquiry?.order.bridal?.feet?.amount) && (
                    <div className="service-container">
                      <h2>BRIDAL</h2>

                      <div className="bridal-content-container">
                        <div className="bridal-services">
                          {inquiry?.order.bridal?.inner?.amount && (
                            <div className="bridal-service">
                              <img alt="" src={inquiry?.order.bridal.inner.inspiration.imageUrl} />
                              <h3>INNER HAND</h3>
                              <p>Design category: {inquiry?.order.bridal?.inner.amount} </p>
                              <p>Design length: Zone {inquiry?.order.bridal?.inner.length} </p>
                            </div>
                          )}
                          {inquiry?.order.bridal?.outer?.amount && (
                            <div className="bridal-service">
                              <img alt="" src={inquiry?.order.bridal.outer.inspiration.imageUrl} />
                              <h3>OUTER HAND</h3>
                              <p>Design category: {inquiry?.order.bridal?.outer.amount} </p>
                              <p>Design length: Zone {inquiry?.order.bridal?.outer.length} </p>
                            </div>
                          )}

                          {inquiry?.order.bridal?.feet?.amount && (
                            <div className="bridal-service">
                              <img alt="" src={inquiry?.order.bridal.feet.inspiration.imageUrl} />
                              <h3>FEET</h3>
                              <p>Design category: {inquiry?.order.bridal?.feet.amount} </p>
                              <p>Design length: Zone {inquiry?.order.bridal?.feet.length} </p>
                            </div>
                          )}
                        </div>

                        <div className="text-field-container">
                          <TextField
                            sx={textfieldStyle}
                            variant="filled"
                            label="Notes for bridal henna.."
                            multiline
                            spellCheck={false}
                            value={inquiry.order.bridal.note}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {(inquiry?.order.group1 || inquiry?.order.group2) && (
                    <div className="service-container">
                      <h2>{inquiry?.order.group1 && inquiry?.order.group2 ? "Groups" : "Group"}</h2>
                      <div className="bridal-content-container">
                        <div className="bridal-services">
                          {inquiry?.order.group1 && (
                            <div className="bridal-service">
                              <img
                                alt=""
                                src={inquiry?.order.group1?.designCategory === GROUP_CATEGORIES.SIMPLE ? Simple : Classic}
                              />
                              <h3>Design for group 1</h3>
                              <p>Design category: {inquiry?.order.group1.designCategory} </p>
                              <p>Number of guests: {inquiry?.order.group1?.numberOfPeople} </p>
                              {inquiry?.order.group1.note && <p>Note: {inquiry?.order.group1.note}</p>}
                            </div>
                          )}
                          {inquiry?.order.group2 && (
                            <div className="bridal-service">
                              <img
                                alt=""
                                src={inquiry?.order.group1?.designCategory === GROUP_CATEGORIES.SIMPLE ? Simple : Classic}
                              />
                              <h3>Design for group 2</h3>
                              <p>Design category: {inquiry?.order.group2.designCategory} </p>
                              <p>Number of guests: {inquiry?.order.group2?.numberOfPeople} </p>
                              {inquiry?.order.group2.note && <p>Note: {inquiry?.order.group2.note}</p>}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const textfieldStyle: SxProps = {
  backgroundColor: "#1F1914",
  padding: "10px",
  marginTop: "30px",
  width: "100%",

  "& .MuiInputLabel-root": {
    color: "#ddb28db0",
    fontSize: "12px",
    fontFamily: "Lato-regular",
  },

  "& .MuiFilledInput-root": {
    backgroundColor: "transparent",
    color: "ddb28db0",
    fontSize: "16px",
    fontFamily: "Lato-regular",

    "& .MuiFilledInput-input": {
      color: "#B8977E",
    },
  },
};

export default PublicResume;
