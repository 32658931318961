import { CircularProgress, SxProps } from '@mui/material';


export const loaderStyle: SxProps = {
  position: 'fixed',
  top: '45%',
  left: '45%',

  '& .MuiCircularProgress-svg':{ 
    color: '#B8977E',
    
  }
}

const Loader = () => {
  return <CircularProgress sx={loaderStyle} size={50} />
}

export default Loader;