import { Step, StepLabel, Stepper } from "@mui/material";
import { useAppSelector } from "../../hooks";
import Feet from "./Feet";
import Inner from "./Inner";
import Outer from "./Outer";
import TopContainer from "../../components/TopContainer";
import { stepperStyle } from "../../styles/theme";
import { useDispatch } from "react-redux";
import { setStepNumber } from "../../features/order/stepsSlice";

function BridalSteps() {
  const currentStep = useAppSelector(state => state.steps.currentStep);
  const dispatch = useDispatch()

  const steps = [
    {
      label: 'Inner',
      component: <Inner/>,
    },
    {
      label: 'Outer',
      component: <Outer/>,
    },
    {
      label: 'Feet',
      component: <Feet/>,
    },
  ];

  return (
    <>
    <div className="bridal-container">
    <TopContainer text='Select category according to the amount of desired design.' backButtonRoute="/booking/services" />
      <Stepper sx={{ width: '75vw', margin: '20px 0px 30px 0px'}} activeStep={currentStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.label} sx={stepperStyle} onClick={() => dispatch(setStepNumber(index))} >
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps[currentStep].component}
    </div>
  </>
  );
}

export default BridalSteps;