import { GROUP_CATEGORIES } from "../types";
import { MenuItem, SxProps, TextField } from "@mui/material";
import { GroupType } from "../features/order/orderSlice";

const data = [
  {
    key: 'simple',
    value: GROUP_CATEGORIES.SIMPLE,
  },
  {
    key: 'classic',
    value: GROUP_CATEGORIES.CLASSIC,
  },
]

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

type Props = {
  group: GroupType,
  setGroup: (_group: GroupType) => void;
}

const dropdownStyle: SxProps = {
  backgroundColor: 'white',
  width: '200px',
  borderRadius: 3,
  margin: 1
}

function GroupFormDropdowns(props: Props) {
  const { group, setGroup } = props;

  return (
    <>
      <div className="group-detail-form-row">
        <TextField
        sx={dropdownStyle}
        variant="filled"
        label="Design Category"
        select
        value={group?.designCategory}
        onChange={(e) => setGroup({...group, designCategory: e.target.value as GROUP_CATEGORIES})}
        >
        {data.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
        </TextField>

        <TextField
        sx={dropdownStyle}
        variant="filled"
        select
        type='number'
        label="Number of people"
        value={group?.numberOfPeople}
        onChange={(e) => setGroup({...group, numberOfPeople: parseInt(e.target.value)})}
        >
            {numbers.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
        </TextField>

        <TextField
        sx={dropdownStyle}
        variant="filled"
        label="Note.."
        value={group?.note}
        onChange={(e) => setGroup({...group, note: e.target.value})}
        >
        </TextField>
      </div>
  </>
  );
}

export default GroupFormDropdowns;