import logo from '../assets/images/logo.png';
import Image from '../assets/images/singlepage/how-its-done.jpg';
import SendIcon from '@mui/icons-material/Send';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Button } from '@mui/material';
import { darkButtonStyle } from '../styles/theme';
import { useNavigate } from 'react-router-dom';


const HowItsDonePage = () => {

    const navigate = useNavigate();

    return (
        <>          
            <div id="how-it's-done" className="how-its-done-page-container">
                <div className="content-container">
                    <div className="left-container">
                        <div className="header-container">
                            <img className='logo' src={logo} alt="" />
                            <h1>HOW IT'S DONE</h1>
                        </div>
                        <div className='steps'>
                            <div className="step">
                                <SendIcon />
                                <h4>Get in touch by sending your inquiry with your wishes.</h4>
                            </div>
                            <div className="step">
                                <TaskAltRoundedIcon />
                                <h4>Receive a customized package price and confirm to book the appointment.</h4>
                            </div>
                            <div className="step">
                                <QuestionAnswerIcon />
                                <h4>Catch up a few days before your appointment to plan the day. During this conversation you will be fully informed about prep and aftercare advice.</h4>
                            </div>
                            <div className="step">
                                <VolunteerActivismIcon />
                                <h4>Relax and enjoy your day!</h4>
                            </div>
                            <div className="button-container">
                                <Button onClick={() => navigate('/booking')} sx={darkButtonStyle}>GET STARTED</Button>
                            </div>
                        </div>
                    </div>
                    <div className="right-container">
                        <img src={Image} alt=""/>
                    </div>

                </div>
            </div>
        </>
    );
  }
  
  export default HowItsDonePage;