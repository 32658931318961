import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AmountFeet from "../../components/AmountFeet";
import Grid from "../../components/Grid";
import LengthFeet from "../../components/LengthFeet";
import { InspirationList, gauri, jodha, gauriDescription, jodhaDescription } from "../../db";
import { removeFeet, updateBridal } from "../../features/order/orderSlice";
import { setStepNumber } from "../../features/order/stepsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { skipButtonStyle } from "../../styles/theme";
import { AMOUNT_FEET, Image, LENGTH_FEET } from "../../types";


function Inner() {
  const previouslySelectedInspirationImage = useAppSelector(state => state.order.bridal?.feet?.inspiration)
  const previouslySelectedLength = useAppSelector(state => state.order.bridal?.feet?.length) as LENGTH_FEET;
  const [selectedCategory, setSelectedCategory] = useState<AMOUNT_FEET>();
  const [selectedInspirationImage, setSelectedInspirationImage] = useState<Image | undefined>(previouslySelectedInspirationImage)
  const [inspirationImages, setInspirationImages] = useState<InspirationList>([]);
  const [isSelectingAmount, setIsSelectingAmount] = useState(false);
  const [isSelectingLength, setIsSelectingLength] = useState(false);
  const [description, setDescription] = useState('');
  const dispatch = useAppDispatch();
  const bridal = useAppSelector(state => state.order.bridal);
  const navigate = useNavigate();


  const amountSelected = (selectedCategory: AMOUNT_FEET) => {
    if (!selectedCategory) {
      return;
    }

    switch(selectedCategory) {
      case AMOUNT_FEET.GAURI:
        setInspirationImages(gauri)
        setDescription(gauriDescription)
        break;
      case AMOUNT_FEET.JODHA:
        setInspirationImages(jodha)
        setDescription(jodhaDescription)
        break;
      default:
        console.error('Amount could not be found')
    }
    setSelectedCategory(selectedCategory);

    //Now select inspiration image from grid
    setIsSelectingAmount(true)
  }

  const hasSelectedInspirationImageCallback = (_inspiration: Image) => {
    if(!_inspiration) return

    setSelectedInspirationImage(_inspiration)
    setIsSelectingLength(true)
  }

  const next = (_length: LENGTH_FEET) => {
    if(!selectedCategory || !selectedInspirationImage) return;

    dispatch(updateBridal(
      {
        ...bridal,
        feet: {
          amount: selectedCategory,
          inspiration: selectedInspirationImage,
          length: _length
        }
    }))

    dispatch(setStepNumber(0))
    navigate("/booking/services")
  }

  const skip = () => {
    setSelectedInspirationImage(undefined);
    dispatch(removeFeet());
    dispatch(setStepNumber(0))
    navigate("/booking/services")
  }

  return (
      <div className="inner-container">
        { selectedCategory &&
        <Grid 
          isGridOpen={isSelectingAmount}
          inspirationImages={inspirationImages} 
          selectedCategory={selectedCategory}
          closeGrid={() => setIsSelectingAmount(false)}
          hasSelectedInspirationImageCallback={(inspiration) => hasSelectedInspirationImageCallback(inspiration)}
          previouslySelectedInspirationImage={previouslySelectedInspirationImage}
          description={description}
          />
        }
          <LengthFeet
            isLengthDialogOpen={isSelectingLength}
            closeLengthDialog={() => setIsSelectingLength(false)}
            hasSelectedLengthCallback={(selectedLength: LENGTH_FEET) => next(selectedLength)}
            previouslySelectedLength={previouslySelectedLength}
           />
        <AmountFeet amountSelected={(selectedAmount) => amountSelected(selectedAmount)} />
        <Button style={skipButtonStyle} onClick={skip}>Skip feet design</Button>
      </div>
  );
}

export default Inner;