import { Button } from "@mui/material";

type Props = {
    text: string,
    imageUrl: string
}

function Service(props: Props) {
    const { text, imageUrl } = props

    return (
            <div className='service-page-container'>
                <img className="service-image" src={imageUrl} alt="" />
                <div className="service-text">{text}</div>
            </div>
    );
  }
  
  export default Service;