import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface StepsState {
	currentStep: number;
}
  
// Define the initial state using that type
const initialState: StepsState = {
	currentStep: 0, 
};

export const StepsSlice = createSlice({
	name: 'steps',
	initialState,
	reducers: {
		nextStep: (state) => { 
			state.currentStep++
		},
		previousStep: (state) => { 
			state.currentStep--
		},
		setStepNumber: (state, action: PayloadAction<number>) => { 
			state.currentStep = action.payload
		}
	}
});

export const { nextStep, previousStep, setStepNumber } = StepsSlice.actions;

export default StepsSlice.reducer;