import { useState, useEffect } from "react";
import { send } from "@emailjs/browser";
import moment from "moment";
import { useAppSelector } from "../hooks";
import { sendButtonStyle } from "../styles/theme";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import TopContainer from "../components/TopContainer";
import { Alert, Button, SxProps, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AlertDialog from "../components/Alert";
import { useDispatch } from "react-redux";
import {
  removeFeet,
  removeGroup1,
  removeGroup2,
  removeInner,
  removeOuter,
  updateBridal,
} from "../features/order/orderSlice";
import { formatDate, isObjectEmpty } from "../utils";
import { addInquiry } from "../firebase";
import { GROUP_CATEGORIES, STATUS } from "../types";
import Simple from "../assets/images/group/simple.jpg";
import Classic from "../assets/images/group/classic.jpg";

enum REMOVABLES {
  INNER = "Inner hand",
  OUTER = "Outer hand",
  FEET = "Feet",
  GROUP1 = "Group 1",
  GROUP2 = "Group 2",
}

const Resume = () => {
  const order = useAppSelector((state) => state.order);
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedRemovable, setSelectedRemovable] = useState<REMOVABLES | undefined>();
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [errorHappened, setErrorhappened] = useState(false);

  const sendInquiry = () => {
    setLoading(true);

    var message = {
      name: user.name,
      email: user.email,
      location: user.location,
      weddingDate: user.weddingDate,
      hennaDate: user.hennaDate,
      order,
      status: STATUS.NEW
    };

    addInquiry(message)
      .then((id) => {
        send("service_fltrc31", "template_8bqel9e", { name: user.name, email: user.email, id }, "D-aWyEbOaQqp8FJ5S")
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              navigate("/booking/thankyou");
            } else {
              console.error(response.status, response.text);
              setErrorhappened(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setErrorhappened(true);
          });
      })
      .catch((error) => {
        console.error(error);
        setErrorhappened(true);
      });
  };

  const remove = () => {
    switch (selectedRemovable) {
      case REMOVABLES.INNER:
        dispatch(removeInner());
        break;
      case REMOVABLES.OUTER:
        dispatch(removeOuter());
        break;
      case REMOVABLES.FEET:
        dispatch(removeFeet());
        break;
      case REMOVABLES.GROUP1:
        dispatch(removeGroup1());
        break;
      case REMOVABLES.GROUP2:
        dispatch(removeGroup2());
        break;
      default:
        console.error(`Sorry, something went wrong..`);
    }
    setSelectedRemovable(undefined);
  };

  useEffect(() => {
    if (selectedRemovable !== undefined) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
      setSelectedRemovable(undefined);
    }
  }, [selectedRemovable]);

  useEffect(() => {
    if (isObjectEmpty(order)) {
      navigate("/booking/services");
    }
  }, [order, navigate]);

  return (
    <>
      {errorHappened && (
        <Alert sx={{ position: "sticky", top: 0, zIndex: 1 }} variant="filled" severity="error">
          Der er sket en teknisk fejl. Du bedes kontakte Henna By Harishmi på Facebook.
        </Alert>
      )}
      <AlertDialog
        open={openAlert}
        setOpen={(value) => setOpenAlert(value)}
        header="Are you sure?"
        description={`You are about to delete '${selectedRemovable}' from your order. Are you sure you want to proceed?`}
        yesCallback={() => remove()}
        noCallback={() => setSelectedRemovable(undefined)}
      />
      <TopContainer headerText="RESUME" backButtonRoute="/booking/services" />
      <div className="resume-outer-container">
        <div className="resume-inner-container">
          <div className="service-container">
            <h2>INFORMATION</h2>
            <div className="resume-information-container">
              <div className="row">
                <h4>Name</h4>
                <h5>{user.name}</h5>
              </div>

              <div className="row">
                <h4>Email</h4>
                <h5>{user.email}</h5>
              </div>

              <div className="row">
                <h4>Henna date</h4>
                <h5>{user.hennaDate && formatDate(user.hennaDate)}</h5>
              </div>

              <div className="row">
                <h4>Wedding date</h4>
                <h5>{user.weddingDate && formatDate(user.weddingDate)}</h5>
              </div>
            </div>
          </div>
          {(order.bridal?.inner?.amount || order.bridal?.outer?.amount || order.bridal?.feet?.amount) && (
            <div className="service-container">
              <h2>BRIDAL</h2>

              <div className="bridal-content-container">
                <div className="bridal-services">
                  {order.bridal?.inner?.amount && (
                    <div className="bridal-service">
                      <img alt="" src={order.bridal.inner.inspiration.imageUrl} />
                      <h3>INNER HAND</h3>
                      <p>Design category: {order.bridal?.inner.amount} </p>
                      <p>Design length: Zone {order.bridal?.inner.length} </p>
                      <Button className="delete-button" onClick={() => setSelectedRemovable(REMOVABLES.INNER)}>
                        <ClearIcon />
                      </Button>
                    </div>
                  )}
                  {order.bridal?.outer?.amount && (
                    <div className="bridal-service">
                      <img alt="" src={order.bridal.outer.inspiration.imageUrl} />
                      <h3>OUTER HAND</h3>
                      <p>Design category: {order.bridal?.outer.amount} </p>
                      <p>Design length: Zone {order.bridal?.outer.length} </p>
                      <Button className="delete-button" onClick={() => setSelectedRemovable(REMOVABLES.OUTER)}>
                        <ClearIcon />
                      </Button>
                    </div>
                  )}

                  {order.bridal?.feet?.amount && (
                    <div className="bridal-service">
                      <img alt="" src={order.bridal.feet.inspiration.imageUrl} />
                      <h3>FEET</h3>
                      <p>Design category: {order.bridal?.feet.amount} </p>
                      <p>Design length: Zone {order.bridal?.feet.length} </p>
                      <Button className="delete-button" onClick={() => setSelectedRemovable(REMOVABLES.FEET)}>
                        <ClearIcon />
                      </Button>
                    </div>
                  )}
                </div>

                <div className="text-field-container">
                  <TextField
                    sx={textfieldStyle}
                    variant="filled"
                    label="Notes for bridal henna.."
                    multiline
                    spellCheck={false}
                    value={order.bridal.note}
                    onChange={(e) => dispatch(updateBridal({ ...order.bridal, note: e.target.value }))}
                  />
                </div>
              </div>
            </div>
          )}

          {(order.group1 || order.group2) && (
            <div className="service-container">
              <h2>{order.group1 && order.group2 ? "Groups" : "Group"}</h2>
              <div className="bridal-content-container">
                <div className="bridal-services">
                  {order.group1 && (
                    <div className="bridal-service">
                      <img alt="" src={order.group1?.designCategory === GROUP_CATEGORIES.SIMPLE ? Simple : Classic} />
                      <h3>Design for group 1</h3>
                      <p>Design category: {order.group1.designCategory} </p>
                      <p>Number of guests: {order.group1?.numberOfPeople} </p>
                      {order.group1.note && <p>Note: {order.group1.note}</p>}
                      <Button className="delete-button" onClick={() => setSelectedRemovable(REMOVABLES.GROUP1)}>
                        <ClearIcon />
                      </Button>
                    </div>
                  )}
                  {order.group2 && (
                    <div className="bridal-service">
                      <img alt="" src={order.group1?.designCategory === GROUP_CATEGORIES.SIMPLE ? Simple : Classic} />
                      <h3>Design for group 2</h3>
                      <p>Design category: {order.group2.designCategory} </p>
                      <p>Number of guests: {order.group2?.numberOfPeople} </p>
                      {order.group2.note && <p>Note: {order.group2.note}</p>}
                      <Button className="delete-button" onClick={() => setSelectedRemovable(REMOVABLES.GROUP2)}>
                        <ClearIcon />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="button-container">
          <LoadingButton
            sx={sendButtonStyle}
            onClick={sendInquiry}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
          >
            <span>SEND INQUIRY</span>
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

const textfieldStyle: SxProps = {
  backgroundColor: "#1F1914",
  padding: "10px",
  marginTop: "30px",
  width: "100%",

  "& .MuiInputLabel-root": {
    color: "#ddb28db0",
    fontSize: "12px",
    fontFamily: "Lato-regular",
  },

  "& .MuiFilledInput-root": {
    backgroundColor: "transparent",
    color: "ddb28db0",
    fontSize: "16px",
    fontFamily: "Lato-regular",

    "& .MuiFilledInput-input": {
      color: "#B8977E",
    },
  },
};

export default Resume;
