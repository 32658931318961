import { useNavigate } from 'react-router-dom';
import Option from '../components/Option';
import BridalHenna from '../assets/images/bridal/bridal_henna.jpg';
import GroupHenna from '../assets/images/group/group.jpg';
import { SIZE } from '../types';
import TopContainer from '../components/TopContainer';
import { Button } from '@mui/material';
import { darkButtonStyle } from '../styles/theme';
import { useAppSelector } from '../hooks';
import { isObjectEmpty } from '../utils';

const Services = () => {
  const navigate = useNavigate();
  const order = useAppSelector(state => state.order);

  return (
    <>
      <TopContainer text='Select your desired service to your inquiry' backButtonRoute='/booking' />
      <div className='services-container'>
        <Option
          headerText='Bridal'
          imageUrl={BridalHenna}
          callback={() => navigate('/booking/bridal')}
          size={SIZE.LARGE}
        />
        <Option
          headerText='Group'
          imageUrl={GroupHenna}
          callback={() => navigate('/booking/group')}
          size={SIZE.LARGE}
        />
      </div>
      {
      !isObjectEmpty(order) && <Button onClick={() => { navigate("/booking/resume") }} sx={darkButtonStyle}>VIEW INQUIRY</Button>
      }
    </>

  );
}

export default Services;