import HomePageImage from '../assets/images/singlepage/homepage.png';
import Logo from '../assets/images/logo.png';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const button = {
    width: '160px',
    borderWidth: '2px',
    top: '15px',
    borderRadius: '6px',
    fontSize: '16px',
    fontFamily: 'Lato',
    letterSpacing: '2px',
    border: '1px solid #ddb28db0'
}

const Homepage = () => {

    const navigate = useNavigate()

    return (
        <div id='home' className='home-page-container'>          
            <div className="logo-container">
              <img className='homepage-logo' src={Logo} alt="" />
              <Button onClick={() => navigate('/booking')} sx={button}>Inquire</Button>
            </div>
            <img className='homepage-background-image' src={HomePageImage} alt="" />
        </div>
    );
  }
  
  export default Homepage;