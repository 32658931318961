import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface User {
	name: string,
	email: string,
	location: string
	hennaDate?: number | null,
	weddingDate?: number | null
}
  
// Define the initial state using that type
const initialState: User = {
	name: '',
	email: '',
	location: '',
	hennaDate: null,
	weddingDate: null
};

export const UserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUser: (state, action: PayloadAction<User>) => { 
			return state = action.payload;
		},
	}
});

export const { updateUser } = UserSlice.actions;

export default UserSlice.reducer;