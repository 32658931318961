import Logo from '../assets/images/logo.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SxProps } from '@mui/system';
import { useNavigate } from 'react-router-dom';


type Props = {
  headerText?: string,
  text?: string
  backButtonRoute?: string
}

const arrowBackButtonStyle: SxProps = {
  color: '#B8977E',
  fontSize: 40,
  position: 'absolute',
  left: 30,
  top: 30,

  ":hover": {
    cursor: 'pointer'
  }
}

const TopContainer = (props: Props) => {
  const { headerText, text, backButtonRoute } = props

  const navigate = useNavigate();

  return (
    <div className='top-container'>
      {backButtonRoute && <ArrowBackIcon sx={arrowBackButtonStyle} onClick={() => navigate(backButtonRoute)} />}
      <img className='logo' src={Logo} alt="" />
      {headerText && <h1>{ headerText }</h1>}
      {text && <h4 style={{width: '50%', lineHeight: '32px'}}>{ text }</h4>}
    </div>
  );
}

export default TopContainer;

