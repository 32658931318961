function NavigationBar() {

    const scrollTo = (id: string) => {
        document.getElementById(id)?.scrollIntoView(
            {
                behavior: "smooth", 
                block: "start", 
                inline: "nearest"
            }
        )
    }

    return (
        <>
            <ul className='navigation'>
                <li className='navigation-item'><button onClick={() => scrollTo('home')}>HOME</button></li>
                <li className='navigation-item'><button onClick={() => scrollTo('artist')}>THE ARTIST</button></li>
                <li className='navigation-item'><button onClick={() => scrollTo('art')}>THE ART</button></li>
                <li className='navigation-item'><button onClick={() => scrollTo('services')}>SERVICES</button></li>
                <li className='navigation-item'><button onClick={() => scrollTo("how-it's-done")}>HOW IT'S DONE</button></li>
                <li className='navigation-item'><button onClick={() => scrollTo('faq')}>FAQ</button></li>
            </ul>
        </>
    );
  }
  
  export default NavigationBar;