import { initializeApp } from 'firebase/app';
import { collection, getFirestore, addDoc, query, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import { STATUS } from './types';


const firebaseConfig = {
    apiKey: "AIzaSyDzl2G2hOPCtHhQ2eyPMqzmKljn_obck9o",
    authDomain: "henna-95a56.firebaseapp.com",
    projectId: "henna-95a56",
    storageBucket: "henna-95a56.appspot.com",
    messagingSenderId: "398586412774",
    appId: "1:398586412774:web:cd9b1f5c4c0b8d8c514527"
};
  

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const ref = collection(db, 'inquiries');

const addInquiry = async(message: any) => {
    const docRef = await addDoc(ref, message);
    return docRef.id;
}

const updateInquiry = async(inquiryId: string, status: STATUS) => {
    return await updateDoc(doc(db, "inquiries", inquiryId), {status});   
}

const getAllInquiries = async() : Promise<Array<any>> => {
    const q = query(collection(db, "inquiries"));

    const querySnapshot = await getDocs(q);
    const data: Array<any> = [];

    querySnapshot.forEach((doc) => {
        data.push(
            {
                ...doc.data(),
                id: doc.id,
            }
        )
      });
      return data;
}

const dbDeleteInquiry = (id: string) => {
    return deleteDoc(doc(db, "inquiries", id));
}

const auth = getAuth();

const signIn = async(email: string, password: string) : Promise<UserCredential> => {
    return await signInWithEmailAndPassword(auth, email, password);
}


export { addInquiry, getAllInquiries, dbDeleteInquiry, signIn, auth, updateInquiry };