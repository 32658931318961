import moment from "moment";
import { STATUS } from "./types";

export const isObjectEmpty = (obj: any) => {
    if(obj === undefined) {
        return true
    }
    
    return Object.keys(obj).length === 0;
}

export const formatDate = (unixTimestamp: number) => {
	return moment.unix(unixTimestamp).format("DD/MM/YYYY");
}

export const getEnumDisplayValue = (value: STATUS) => {
    switch (value) {
        case STATUS.NEW:
            return 'Ny forespøgsel'
        case STATUS.AWAITING:
            return 'Afventer kunde'
        case STATUS.CONFIRMED:
            return 'Bekræftet'
        case STATUS.PAYMENT:
            return 'Til betaling'    
        case STATUS.COMPLETED:
            return 'Afsluttet'
        case STATUS.CANCELLED:
            return 'Annulleret'
    }
}