import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import AmountGroup from "../../components/AmountGroup";
import GroupFormDropdowns from "../../components/GroupFormDropdowns";
import TopContainer from "../../components/TopContainer";
import { updateGroup1, updateGroup2, removeGroup1, removeGroup2 } from "../../features/order/orderSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { darkButtonStyle } from "../../styles/theme";
import { GroupType } from '../../features/order/orderSlice'
import { GROUP_CATEGORIES } from "../../types";
import { useNavigate } from "react-router";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';


const GroupOption = () => {

  const previouslySelectedGroup1 = useAppSelector(state => state.order.group1)
  const previouslySelectedGroup2 = useAppSelector(state => state.order.group2)
  const [anotherCategory, setAnotherCategory] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setAnotherCategoryHandler = () => {
    dispatch(removeGroup2());
    setAnotherCategory(false)
  }

  const [group1, setGroup1] = useState<GroupType>(
    previouslySelectedGroup1??
    {
      designCategory: GROUP_CATEGORIES.SIMPLE, 
      numberOfPeople: 1, 
      note: '', 
    }
  );

  const [group2, setGroup2] = useState<GroupType>(
    previouslySelectedGroup2??
    {
      designCategory: GROUP_CATEGORIES.CLASSIC, 
      numberOfPeople: 1, 
      note: '', 
    }
  );

  useEffect(() => {
    if(previouslySelectedGroup2) {
      setAnotherCategory(true)
    }
  }, [group2, previouslySelectedGroup2])
  
  const isFinished = () => {
    if(!group1) return;

    if(group1.numberOfPeople > 0) {
      dispatch(updateGroup1(
        {
          designCategory: group1.designCategory,
          numberOfPeople: group1.numberOfPeople,
          note: group1.note,
      }))
    } else {
      dispatch(removeGroup1());
    }


    if(!anotherCategory || group2.numberOfPeople === 0) {
      dispatch(removeGroup2());
    } else {
      dispatch(updateGroup2(
        {
          designCategory: group2.designCategory,
          numberOfPeople: group2.numberOfPeople,
          note: group2.note,
      }))
    }
    
    navigate("/booking/services")
  }

  return (
    <>
      <TopContainer text='Please enter your desired design category for your group. Add another category section if more than one design category is required for your group.' backButtonRoute="/booking/services" />
      <AmountGroup/>
      <div className="group-detail-form">
        <h4>GROUP DETAIL FORM</h4>
        <GroupFormDropdowns group={group1} setGroup={(_group: GroupType) => setGroup1(_group)} />
        {
          !anotherCategory &&
        <Button onClick={() => setAnotherCategory(true)}  className="another-category-button">
          <AddIcon sx={{':hover': { cursor: 'pointer' }}}/>
          Add another category
        </Button>
        }
        {
          anotherCategory && 
          <div className="another-category">
            <GroupFormDropdowns group={group2} setGroup={(_group: GroupType) => setGroup2(_group)} />
            <ClearIcon onClick={setAnotherCategoryHandler} sx={{':hover': { cursor: 'pointer' }}} />
          </div>
        }
        <p className="note-text">*Number of people indicates that henna design is made for 2 hand sides per person. Please specify if anything else is required.</p>
        <Button 
          onClick={() => isFinished()} 
          sx={darkButtonStyle}
        >ADD TO INQUIRY</Button>
      </div>
    </>
  );
}

export default GroupOption;