import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { questions as questionsFromDb } from '../db';

type Question = {
    id: string,
    question: string,
    answer: string,
    isSelected: boolean
}

const Faq = () => {
    const onQuestionSelectedHandler = (_questionId: string) => {
        const newQuestions = questions.map(question => {
            if(question.isSelected) {
                return {
                    ...question,
                    isSelected: false
                }
            }
            if (_questionId === question.id) {
                return {
                    ...question,
                    isSelected: true
                }
                } else {
                    return {
                    ...question,
                    isSelected: false
                    }
                }
            }
            )
            setQuestions(newQuestions);
        }

    const [questions, setQuestions] = useState<Array<Question>>(questionsFromDb)



    return (
        <>          
            <div id='faq' className="faq-page-container">
                    <h1>FAQ</h1>

                    <div className="questions-container">
                        {
                            questions.map((question) => (
                                <div className='question-container' key={question.id}>
                                    <div className={question.isSelected ? 'question question--selected' : 'question'} onClick={() => onQuestionSelectedHandler(question.id)}>
                                        <h4>{question.question}</h4>
                                        {
                                            question.isSelected ? <KeyboardArrowDownIcon sx={{color: '#6b594b'}} /> : <KeyboardArrowRightIcon/>
                                        }
                                    </div>
                                    {
                                        question.isSelected &&
                                        <div className="answer">
                                            <h5>
                                            {question.answer}
                                            </h5>
                                        </div>
                                    }
                                </div>
                           )
                        )
                        }
                    </div>
            
            </div>
        </>
    );
  }
  
  export default Faq;