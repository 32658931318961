import { DesktopDatePicker, MobileDatePicker } from '@mui/lab';
import { TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TopContainer from '../components/TopContainer';
import { updateUser } from '../features/order/userSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SxProps } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import moment from 'moment'
import 'moment/locale/da'

const buttonStyle: SxProps = {
  width: 160,
  height: 50,
  backgroundColor: "#120C0E",
  color: '#866D59',
  borderRadius: 5,
  fontSize: 20,
  fontFamily: 'Lato-light',
  margin: "35px 0px 15px 0px",

  ":disabled": {
    color: '#4C3D34',
    fontStyle: 'italic',
    opacity: 0.9,
    cursor: 'default'
  },
  ":hover": {
    backgroundColor: "#120C0E",
  }
}


function InformationStep() {
  const user = useAppSelector(state => state.user);

  const dispatch = useAppDispatch();
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [location, setLocation] = useState(user.location);
  const [hennaDate, setHennaDate] = useState(user.hennaDate);
  const [weddingDate, setWeddingDate] = useState(user.weddingDate);
  const desktopSize = useMediaQuery('(min-width:768px)');
  const navigate = useNavigate();
  const [dateError, setDateError] = useState(false)


  const datePickerStyle: SxProps = {
    "label": {
      fontFamily: 'Lato-regular'
    },
    ".MuiOutlinedInput-root": {
      borderRadius: 5,
      width: desktopSize ? "300px" : "200px",
      fontSize: "16px",
      fontFamily: 'Lato-regular'
    }
  }

  const textfieldStyle: SxProps = {
    width: "80%",
    
    "& .MuiFilledInput-root": { 
      backgroundColor: 'transparent'
    },
    "& .MuiFilledInput-root.Mui-focused": { 
      backgroundColor: 'transparent'
    },
    input: {
      textAlign: 'center', 
      color: 'black', 
      fontSize: desktopSize ? 13 : 10,
      fontFamily: 'Lato-regular',
      letterSpacing: '3px'
    },
    ":hover": {
      backgroundColor: 'transparent'
    }
  }

  const handleHennaDateChange = (newValue: moment.Moment | null) => {
    if(!newValue) {
      console.error('Invalid date')
      return;
    }
    setDateError(false)
    setHennaDate(newValue.unix())
  };

  const handleWeddingDateChange = (newValue: moment.Moment | null) => {
    if(!newValue) {
      console.error('Invalid date')
      return;
    }
    setDateError(false)
    setWeddingDate(newValue.unix());
  };

  const next = () => {
    dispatch(updateUser({ name,email,location,hennaDate,weddingDate }))
    navigate("/booking/services")
  }  
  
  return (
    <>
      <TopContainer headerText='INQUIRY' text='Please fill out the form below to start your inquiry.' />
      <div className='information-container'>
        <div className='input-field-text' >
          <label>FULL NAME</label>
          <TextField 
            label=""
            variant="filled"
            color="primary"
            value={name}
            spellCheck={false}
            onChange={e => setName(e.target.value)}
            sx={textfieldStyle}
            />
        </div>

        <div className='input-field-text'>
          <label>EMAIL</label>
          <TextField
            label=""
            variant="filled"
            color="primary"
            type='email'
            spellCheck={false}
            value={email}
            onChange={e => setEmail(e.target.value)}
            sx={textfieldStyle}
            />
          </div>

        <div className='input-field-text'>
          <label>LOCATION OF EVENT</label>
          <TextField
            label=""
            variant="filled"
            spellCheck={false}
            color="primary"
            value={location}
            onChange={e => setLocation(e.target.value)}
            sx={textfieldStyle}
            />
          </div>

          { desktopSize ?
          <>
              <div className='input-field-text'>
                <DesktopDatePicker
                  label="DATE OF WEDDING"
                  inputFormat="DD/MM/yyyy"
                  value={ weddingDate ? moment.unix(weddingDate) : null}
                  onChange={handleWeddingDateChange}
                  disablePast
                  onError={() => setDateError(true)}
                  renderInput={(params) => <TextField {...params} sx={datePickerStyle} />}
                  />
              </div>
              
              <div className='input-field-date'>
                <DesktopDatePicker
                  label="DATE OF HENNA"
                  inputFormat="DD/MM/yyyy"
                  maxDate={weddingDate ? moment.unix(weddingDate).subtract(1, 'd') : undefined}
                  value={hennaDate ? moment.unix(hennaDate) : null}
                  onChange={handleHennaDateChange}
                  disablePast
                  onError={() => setDateError(true)}
                  renderInput={(params) => <TextField {...params} sx={datePickerStyle} />}
                />
              </div>
            </>
            : 
            <>
              <div className='input-field-text'>
                <MobileDatePicker
                  label="DATE OF WEDDING"
                  inputFormat="DD/MM/yyyy"
                  value={ weddingDate ? moment.unix(weddingDate) : null}
                  onChange={handleWeddingDateChange}
                  disablePast
                  onError={() => setDateError(true)}
                  renderInput={(params) => <TextField {...params} sx={datePickerStyle} />}
                />
              </div>
              
              <div className='input-field-date'>
                <MobileDatePicker
                  label="DATE OF HENNA"
                  maxDate={weddingDate ? moment.unix(weddingDate).subtract(1, 'd') : undefined}
                  inputFormat="DD/MM/yyyy"
                  value={hennaDate ? moment.unix(hennaDate) : null}
                  onChange={handleHennaDateChange}
                  disablePast
                  onError={() => setDateError(true)}
                  renderInput={(params) => <TextField {...params} sx={datePickerStyle} />}
                />
              </div>
            </>
          }
          <Button sx={buttonStyle} 
          disabled={
            !(
              name !=='' && 
              email !=='' && 
              location && 
              !dateError && 
              Boolean(hennaDate) && 
              Boolean(weddingDate))} 
              onClick={next}>
              NEXT
          </Button>
      </div>
    </>
  );
}

export default InformationStep;