import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes, useNavigate } from "react-router-dom";
import InformationStep from './booking/InformationStep';
import Services from './booking/Services';
import NotFound from './booking/NotFound';
import Main from './booking/BridalSteps/BridalSteps';
import HomePage from './pages/HomePage';
import AboutTheArtistPage from './pages/AboutTheArtistPage';
import AboutTheArtPage from './pages/AboutTheArtPage';
import NavigationBar from './pages/components/NavigationBar';
import ServicesPage from './pages/ServicesPage';
import { theme } from './styles/theme';
import GroupOption from './booking/GroupSteps/GroupStep';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ThankYou from './booking/ThankYou';
import Resume from './booking/Resume';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import HowItsDonePage from './pages/HowItsDonePage';
import Faq from './pages/Faq';
import Inquiries from './admin/Inquries';
import PublicResume from './admin/PublicResume';
import { setIsBootstrapped } from './features/order/appSlice';
import { getAllInquiries } from './firebase';
import { setAllInquiries } from './features/order/appSlice';
import Loader from './components/Loader';
import Footer from './pages/components/Footer';

const App = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const isBootstrapped = useAppSelector(state => state.app.isBootstrapped);
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(!isBootstrapped) {
      setLoading(true);
      getAllInquiries().then(data => {
        dispatch(setAllInquiries(data));
        dispatch(setIsBootstrapped(true));
        setLoading(false);
      });
    } else {
        if(location.pathname === '/booking') return;
        
        if(location.pathname.includes('booking')) {
          if(!(user.name && user.email && user.location && user.hennaDate && user.weddingDate)) {
            navigate('/booking')
          }
        }
    
        window.scrollTo(0, 0)
    }

  }, [location, navigate, user, dispatch, isBootstrapped])

  const Homepage = () => {
    return <>
      <NavigationBar />
      <HomePage />
      <AboutTheArtistPage />
      <AboutTheArtPage />
      <ServicesPage />
      <HowItsDonePage /> 
      <Faq/>
      <Footer />
    </>
  }
  return (
    <>
    {
      loading
       ?
        <Loader/>
        : 
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
            <div className='App'>
              <Routes>
                <Route path="/" element={Homepage()} />
                <Route path="/admin" element={<Inquiries/>} />
                <Route path="/inquiry/:id" element={<PublicResume />} />
                <Route path="/booking" element={<InformationStep />} />
                <Route path="/booking/services" element={<Services />} />
                <Route path="/booking/bridal" element={<Main />} />
                <Route path="/booking/group" element={<GroupOption />} />
                <Route path="/booking/resume" element={<Resume />} />
                <Route path="/booking/thankyou" element={<ThankYou />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
      </ThemeProvider>
    </LocalizationProvider>
  }
  </>
);
}

export default App;