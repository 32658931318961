import leela_inner_1 from './assets/images/bridal/leela/inner/1_leela inner.jpg';
import leela_inner_2 from './assets/images/bridal/leela/inner/2_leela inner.jpg';
import leela_inner_3 from './assets/images/bridal/leela/inner/3_leela inner.jpg';
import leela_inner_4 from './assets/images/bridal/leela/inner/4_leela inner.jpg';
import leela_inner_5 from './assets/images/bridal/leela/inner/5_leela inner.jpg';
import leela_inner_6 from './assets/images/bridal/leela/inner/6_leela inner.jpg';

import leela_outer_1 from './assets/images/bridal/leela/outer/1_leela outer.jpg';
import leela_outer_2 from './assets/images/bridal/leela/outer/2_leela outer.jpg';
import leela_outer_3 from './assets/images/bridal/leela/outer/3_leela outer.jpg';
import leela_outer_4 from './assets/images/bridal/leela/outer/4_leela outer.jpg';
import leela_outer_5 from './assets/images/bridal/leela/outer/5_leela outer.jpg';
import leela_outer_6 from './assets/images/bridal/leela/outer/6_leela outer.jpg';
import leela_outer_7 from './assets/images/bridal/leela/outer/7_leela outer.jpg';
import leela_outer_8 from './assets/images/bridal/leela/outer/8_leela outer.jpg';
import leela_outer_9 from './assets/images/bridal/leela/outer/9_leela outer.jpg';

import radha_inner_1 from './assets/images/bridal/radha/inner/1_radha inner.jpg';
import radha_inner_2 from './assets/images/bridal/radha/inner/2_radha inner.jpg';
import radha_inner_3 from './assets/images/bridal/radha/inner/3_radha inner.jpg';
import radha_inner_4 from './assets/images/bridal/radha/inner/4_radha inner.jpg';
import radha_inner_5 from './assets/images/bridal/radha/inner/5_radha inner.jpg';
import radha_inner_6 from './assets/images/bridal/radha/inner/6_radha inner.jpg';
import radha_inner_7 from './assets/images/bridal/radha/inner/7_radha inner.jpg';
import radha_inner_8 from './assets/images/bridal/radha/inner/8_radha inner.jpg';
import radha_inner_9 from './assets/images/bridal/radha/inner/9_radha inner.jpg';

import radha_outer_1 from './assets/images/bridal/radha/outer/1_radha outer.jpg';
import radha_outer_2 from './assets/images/bridal/radha/outer/2_radha outer.jpg';
import radha_outer_3 from './assets/images/bridal/radha/outer/3_radha outer.jpg';
import radha_outer_4 from './assets/images/bridal/radha/outer/4_radha outer.jpg';
import radha_outer_5 from './assets/images/bridal/radha/outer/5_radha outer.jpg';
import radha_outer_6 from './assets/images/bridal/radha/outer/6_radha outer.jpg';
import radha_outer_7 from './assets/images/bridal/radha/outer/7_radha outer.jpg';
import radha_outer_8 from './assets/images/bridal/radha/outer/8_radha outer.jpg';
import radha_outer_9 from './assets/images/bridal/radha/outer/9_radha outer.jpg';

import maharani_inner_1 from './assets/images/bridal/maharani/inner/1_maharani inner.jpg';
import maharani_inner_2 from './assets/images/bridal/maharani/inner/2_maharani inner.jpg';
import maharani_inner_3 from './assets/images/bridal/maharani/inner/3_maharani inner.jpg';
import maharani_inner_4 from './assets/images/bridal/maharani/inner/4_maharani inner.jpg';
import maharani_inner_5 from './assets/images/bridal/maharani/inner/5_maharani inner.jpg';
import maharani_inner_6 from './assets/images/bridal/maharani/inner/6_maharani inner.jpg';
import maharani_inner_7 from './assets/images/bridal/maharani/inner/7_maharani inner.jpg';
import maharani_inner_8 from './assets/images/bridal/maharani/inner/8_maharani inner.jpg';
import maharani_inner_9 from './assets/images/bridal/maharani/inner/9_maharani inner.jpg';

import maharani_outer_1 from './assets/images/bridal/maharani/outer/1_maharani outer.jpg';
import maharani_outer_2 from './assets/images/bridal/maharani/outer/2_maharani outer.jpg';
import maharani_outer_3 from './assets/images/bridal/maharani/outer/3_maharani outer.jpg';
import maharani_outer_4 from './assets/images/bridal/maharani/outer/4_maharani outer.jpg';
import maharani_outer_5 from './assets/images/bridal/maharani/outer/5_maharani outer.jpg';
import maharani_outer_6 from './assets/images/bridal/maharani/outer/6_maharani outer.jpg';
import maharani_outer_7 from './assets/images/bridal/maharani/outer/7_maharani outer.jpg';
import maharani_outer_8 from './assets/images/bridal/maharani/outer/8_maharani outer.jpg';
import maharani_outer_9 from './assets/images/bridal/maharani/outer/9_maharani outer.jpg';

import gauri_1 from './assets/images/bridal/feet/gauri/1_gauri.jpg';
import gauri_2 from './assets/images/bridal/feet/gauri/2_gauri.jpg';
import gauri_3 from './assets/images/bridal/feet/gauri/3_gauri.jpg';
import gauri_4 from './assets/images/bridal/feet/gauri/4_gauri.jpg';
import gauri_5 from './assets/images/bridal/feet/gauri/5_gauri.jpg';
import gauri_6 from './assets/images/bridal/feet/gauri/6_gauri.jpg';
import gauri_7 from './assets/images/bridal/feet/gauri/7_gauri.jpg';
import gauri_8 from './assets/images/bridal/feet/gauri/8_gauri.jpg';
import gauri_9 from './assets/images/bridal/feet/gauri/9_gauri.jpg';

import jodha_1 from './assets/images/bridal/feet/jodha/1_jodha.jpg';
import jodha_2 from './assets/images/bridal/feet/jodha/2_jodha.jpg';
import jodha_3 from './assets/images/bridal/feet/jodha/3_jodha.jpg';
import jodha_4 from './assets/images/bridal/feet/jodha/4_jodha.jpg';
import jodha_5 from './assets/images/bridal/feet/jodha/5_jodha.jpg';
import jodha_6 from './assets/images/bridal/feet/jodha/6_jodha.jpg';
import jodha_7 from './assets/images/bridal/feet/jodha/7_jodha.jpg';
import jodha_8 from './assets/images/bridal/feet/jodha/8_jodha.jpg';
import jodha_9 from './assets/images/bridal/feet/jodha/9_jodha.jpg';

import simple_1 from './assets/images/group/simple/Simple1.jpg';
import simple_2 from './assets/images/group/simple/Simple2.jpg';
import simple_3 from './assets/images/group/simple/Simple3.jpg';
import simple_4 from './assets/images/group/simple/Simple4.jpg';
import simple_5 from './assets/images/group/simple/Simple5.jpg';
import simple_6 from './assets/images/group/simple/Simple6.jpg';
import simple_7 from './assets/images/group/simple/Simple7.jpg';
import simple_8 from './assets/images/group/simple/Simple8.jpg';

import classic_1 from './assets/images/group/classic/Classic1.jpg';
import classic_2 from './assets/images/group/classic/Classic2.jpg';
import classic_3 from './assets/images/group/classic/Classic3.jpg';
import classic_4 from './assets/images/group/classic/Classic4.jpg';
import classic_5 from './assets/images/group/classic/Classic5.jpg';
import classic_6 from './assets/images/group/classic/Classic6.jpg';
import classic_7 from './assets/images/group/classic/Classic7.jpg';
import classic_8 from './assets/images/group/classic/Classic8.jpg';


export type InspirationList = Array <{imageUrl: string, id: string}>

export const leela_inner = [
    {
      imageUrl: leela_inner_1,
      id: 'leela_inner_1',
    },
    {
      imageUrl: leela_inner_2,
      id: 'leela_inner_2',
    },
    {
      imageUrl: leela_inner_3,
      id: 'leela_inner_3',
    },
    {
      imageUrl: leela_inner_4,
      id: 'leela_inner_4',
    },
    {
      imageUrl: leela_inner_5,
      id: 'leela_inner_5',
    },
    {
      imageUrl: leela_inner_6,
      id: 'leela_inner_6',
    },
]

export const leela_outer = [
    {
      imageUrl: leela_outer_1,
      id: 'leela_outer_1',
    },
    {
      imageUrl: leela_outer_2,
      id: 'leela_outer_2',
    },
    {
      imageUrl: leela_outer_3,
      id: 'leela_outer_3',
    },
    {
      imageUrl: leela_outer_4,
      id: 'leela_outer_4',
    },
    {
      imageUrl: leela_outer_5,
      id: 'leela_outer_5',
    },
    {
      imageUrl: leela_outer_6,
      id: 'leela_outer_6',
    },
    {
      imageUrl: leela_outer_7,
      id: 'leela_outer_7',
    },
    {
      imageUrl: leela_outer_8,
      id: 'leela_outer_8',
    },
    {
      imageUrl: leela_outer_9,
      id: 'leela_outer_9',
    },
]

export const radha_inner = [
    {
      imageUrl: radha_inner_1,
      id: 'radha_inner_1',
    },
    {
      imageUrl: radha_inner_2,
      id: 'radha_inner_2',
    },
    {
      imageUrl: radha_inner_3,
      id: 'radha_inner_3',
    },
    {
      imageUrl: radha_inner_4,
      id: 'radha_inner_4',
    },
    {
      imageUrl: radha_inner_5,
      id: 'radha_inner_5',
    },
    {
      imageUrl: radha_inner_6,
      id: 'radha_inner_6',
    },
    {
      imageUrl: radha_inner_7,
      id: 'radha_inner_7',
    },
    {
      imageUrl: radha_inner_8,
      id: 'radha_inner_8',
    },
    {
      imageUrl: radha_inner_9,
      id: 'radha_inner_9',
    },
]

export const radha_outer = [
    {
      imageUrl: radha_outer_1,
      id: 'radha_outer_1',
    },
    {
      imageUrl: radha_outer_2,
      id: 'radha_outer_2',
    },
    {
      imageUrl: radha_outer_3,
      id: 'radha_outer_3',
    },
    {
      imageUrl: radha_outer_4,
      id: 'radha_outer_4',
    },
    {
      imageUrl: radha_outer_5,
      id: 'radha_outer_5',
    },
    {
      imageUrl: radha_outer_6,
      id: 'radha_outer_6',
    },
    {
      imageUrl: radha_outer_7,
      id: 'radha_outer_7',
    },
    {
      imageUrl: radha_outer_8,
      id: 'radha_outer_8',
    },
    {
      imageUrl: radha_outer_9,
      id: 'radha_outer_9',
    },
]


export const maharani_inner = [
  {
    imageUrl: maharani_inner_1,
    id: 'maharani_inner_1',
  },
  {
    imageUrl: maharani_inner_2,
    id: 'maharani_inner_2',
  },
  {
    imageUrl: maharani_inner_3,
    id: 'maharani_inner_3',
  },
  {
    imageUrl: maharani_inner_4,
    id: 'maharani_inner_4',
  },
  {
    imageUrl: maharani_inner_5,
    id: 'maharani_inner_5',
  },
  {
    imageUrl: maharani_inner_6,
    id: 'maharani_inner_6',
  },
  {
    imageUrl: maharani_inner_7,
    id: 'maharani_inner_7',
  },
  {
    imageUrl: maharani_inner_8,
    id: 'maharani_inner_8',
  },
  {
    imageUrl: maharani_inner_9,
    id: 'maharani_inner_9',
  },
]

export const maharani_outer = [
  {
    imageUrl: maharani_outer_1,
    id: 'maharani_outer_1',
  },
  {
    imageUrl: maharani_outer_2,
    id: 'maharani_outer_2',
  },
  {
    imageUrl: maharani_outer_3,
    id: 'maharani_outer_3',
  },
  {
    imageUrl: maharani_outer_4,
    id: 'maharani_outer_4',
  },
  {
    imageUrl: maharani_outer_5,
    id: 'maharani_outer_5',
  },
  {
    imageUrl: maharani_outer_6,
    id: 'maharani_outer_6',
  },
  {
    imageUrl: maharani_outer_7,
    id: 'maharani_outer_7',
  },
  {
    imageUrl: maharani_outer_8,
    id: 'maharani_outer_8',
  },
  {
    imageUrl: maharani_outer_9,
    id: 'maharani_outer_9',
  },
]

export const gauri = [
  {
    imageUrl: gauri_1,
    id: 'gauri_1',
  },
  {
    imageUrl: gauri_2,
    id: 'gauri_2',
  },
  {
    imageUrl: gauri_3,
    id: 'gauri_3',
  },
  {
    imageUrl: gauri_4,
    id: 'gauri_4',
  },
  {
    imageUrl: gauri_5,
    id: 'gauri_5',
  },
  {
    imageUrl: gauri_6,
    id: 'gauri_6',
  },
  {
    imageUrl: gauri_7,
    id: 'gauri_7',
  },
  {
    imageUrl: gauri_8,
    id: 'gauri_8',
  },
  {
    imageUrl: gauri_9,
    id: 'gauri_9',
  },
]

export const jodha = [
  {
    imageUrl: jodha_1,
    id: 'jodha_1',
  },
  {
    imageUrl: jodha_2,
    id: 'jodha_2',
  },
  {
    imageUrl: jodha_3,
    id: 'jodha_3',
  },
  {
    imageUrl: jodha_4,
    id: 'jodha_4',
  },
  {
    imageUrl: jodha_5,
    id: 'jodha_5',
  },
  {
    imageUrl: jodha_6,
    id: 'jodha_6',
  },
  {
    imageUrl: jodha_7,
    id: 'jodha_7',
  },
  {
    imageUrl: jodha_8,
    id: 'jodha_8',
  },
  {
    imageUrl: jodha_9,
    id: 'jodha_9',
  },
]

export const simple = [
    {
      imageUrl: simple_1,
      id: 'simple_1',
    },
    {
      imageUrl: simple_2,
      id: 'simple_2',
    },
    {
      imageUrl: simple_3,
      id: 'simple_3',
    },
    {
      imageUrl: simple_4,
      id: 'simple_4',
    },
    {
      imageUrl: simple_5,
      id: 'simple_5',
    },
    {
      imageUrl: simple_6,
      id: 'simple_6',
    },
    {
      imageUrl: simple_7,
      id: 'simple_7',
    },
    {
      imageUrl: simple_8,
      id: 'simple_8',
    },
]

export const classic = [
    {
      imageUrl: classic_1,
      id: 'classic_1',
    },
    {
      imageUrl: classic_2,
      id: 'classic_2',
    },
    {
      imageUrl: classic_3,
      id: 'classic_3',
    },
    {
      imageUrl: classic_4,
      id: 'classic_4',
    },
    {
      imageUrl: classic_5,
      id: 'classic_5',
    },
    {
      imageUrl: classic_6,
      id: 'classic_6',
    },
    {
      imageUrl: classic_7,
      id: 'classic_7',
    },
    {
      imageUrl: classic_8,
      id: 'classic_8',
    },
]

export const questions = [
  {
      id: '1',
      question: 'How long is the application process?',
      answer: 'It depends on the intricacy of your desired design. But do expect a minimum of 1 hour to 5 hours according to the length and amount of the details. Please note that the process will require a longer time when henna is applied while sitting on the floor.',
      isSelected: false
  },
  {
      id: '2',
      question: 'How can I get a beautiful henna stain? And what color will it become?',
      answer: 'In a matter of 24-48 hours after application, the natural henna paste will begin to stain your skin in shades of maroon/mahogany/brown color. Typically, any shades within these color can be reached and I will share tips and tricks according to your desired stain color. The darkness of your henna also depends on several other factors such as on which part the design is on, how good your aftercare is and your body chemistry with henna.',
      isSelected: false
  },
  {
      id: '3',
      question: 'Would the henna stain be visible on all types of skin colors?',
      answer: 'Results can vary for everyone, but the skin color doesn’t have much to do with that. Everyone can get a stain that is visible! But the darkness of the stain can be affected by many things, such as body temperature, skin type and how well the aftercare is. My best advice is to follow my aftercare instructions after application. And if you are still unsure about how visible the stain can look on your skin tone, please do contact me - I will show you stains on clients of your skin tone.',
      isSelected: false
  },
  {
      id: '4',
      question: 'How long will the henna color stay?',
      answer: 'Typically the stain fades away completely in one to two weeks depending on your skin type and how much you wash it. The henna stains the upper layer of the skin and will fade naturally as the skin exfoliates. If you wish to make it disappear faster, I will share some tips and tricks before the appointment.',
      isSelected: false
  },
  {
      id: '5',
      question: 'Is henna safe? Should I be concerned about allergic reactions?',
      answer: 'I use completely 100% natural henna which is safe for all types of skin. It is homemade by myself to ensure the quality of the paste with proper safe ingredients. The ingredients are only dried henna leaves, water, raw sugar and drops of natural lavender oil.',
      isSelected: false
  },
  {
      id: '6',
      question: 'Can I get black henna? What is black henna?',
      answer: "No, I do not use or encourage the use of black henna or instant henna. Henna is NOT black and when natural henna is used, it will always stain in shades of maroon and brown in a natural way. Black henna contains the majority of bad things for your skin, such as PPD (a chemical found in hair dye), charcoal, kerosene and other chemicals. These ingredients can lead to permanent scarring and painful sores. So stay away from anything unless it's natural!",
      isSelected: false
  }
];


export const classicDescription = 'Classic medium sized designs made with both indo-arabic and traditional elements. Consist of mostly diagonal, triangular and round design shapes. Includes small design on the fingertips. Recommended for groups less than 10 persons. The design samples below indicate the style of design in this category.'
export const simpleDescription = 'Small delicate and minimalistic designs made with both indo-arabic and traditional elements. Consists of mostly diagonal and triangular design shapes. Recommended for groups of more than 10 persons. The design samples below indicate the style of design in this category.';
export const gauriDescription = 'For the bride who wants something simple and delicate on the feet. Consist of simple elements extending in a flowy design to the ankle.';
export const jodhaDescription = 'For the bride who wants a heavy traditional design on the feet. Consist of intricate elements that fills the tops of the feet to the ankle.';
export const leelaDescription = 'For the minimalist bride who would like to go with a more simple design on her big day. Consist of simple elements and custom henna.';
export const radhaDescription = 'For the bride who wants the perfect in-between the modern elegant and intricate traditional look. Consist of both simple, intricate elements and custom henna.';
export const maharaniDescription = 'For the bride who wants to do bridal henna in all traditional ways! Consist of small intricate details and custom henna.';
