import { useState, useEffect } from "react";
import { LENGTH_HANDS } from "../types";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { lightButtonStyle } from "../styles/theme";
import Button from '@mui/material/Button';
import Hand4 from '../assets/svg/hand/zone_d.png'
import Hand3 from '../assets/svg/hand/zone_c.png'
import Hand2 from '../assets/svg/hand/zone_b.png'
import Hand1 from '../assets/svg/hand/zone_a.png'
import Hand4_henna from '../assets/svg/hand/zone_d_henna.png'
import Hand3_henna from '../assets/svg/hand/zone_c_henna.png'
import Hand2_henna from '../assets/svg/hand/zone_b_henna.png'
import Hand1_henna from '../assets/svg/hand/zone_a_henna.png'

type Props = {
  hasSelectedLengthCallback: (selectedLength: LENGTH_HANDS) => void;
  isLengthDialogOpen: boolean;
  closeLengthDialog: () => void;
  previouslySelectedLength?: LENGTH_HANDS
}


function LengthHands(props: Props) {

  const { closeLengthDialog, hasSelectedLengthCallback, isLengthDialogOpen, previouslySelectedLength } = props;

  const [selectedLength, setSelectedLength] = useState<LENGTH_HANDS | undefined>(previouslySelectedLength);

  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [showC, setShowC] = useState(false);
  const [showD, setShowD] = useState(false);

  const onCloseDialog = () => {
    setSelectedLength(undefined);
    closeLengthDialog();
  }

  const save = (e:any) => {
    if (selectedLength) {
      hasSelectedLengthCallback(selectedLength);
      closeLengthDialog();
    }      
  }

  useEffect(() => {
    previouslySelectedLength && zoneSelectedHandler(previouslySelectedLength);
  }, [previouslySelectedLength])
  

  const zoneSelectedHandler = (zone: LENGTH_HANDS ) => {    
    switch(zone) {
      case LENGTH_HANDS.A:
        setShowD(false)
        setShowC(false)
        setShowB(false)
        setShowA(true);
        setSelectedLength(LENGTH_HANDS.A)
        break;
      case LENGTH_HANDS.B:
        setShowD(false)
        setShowC(false)
        setShowB(true)
        setShowA(true);
        setSelectedLength(LENGTH_HANDS.B)
        break;
      case LENGTH_HANDS.C:
        setShowD(false)
        setShowC(true)
        setShowB(true)
        setShowA(true);
        setSelectedLength(LENGTH_HANDS.C)
        break;
      case LENGTH_HANDS.D:
        setShowD(true)
        setShowC(true)
        setShowB(true)
        setShowA(true);
        setSelectedLength(LENGTH_HANDS.D)
        break;
      default:
        // code block
    }
  }

  return (
    <Dialog onClose={() => onCloseDialog()} open={isLengthDialogOpen}>
    <DialogContent>
      <div className='dialog-container'>
          <h1>Length</h1>
          <p>Please choose the required length of your design.</p>
          <br></br>
          <div className='length-image-container'>
            <div className="zone-container">
              <Button onClick={() => zoneSelectedHandler(LENGTH_HANDS.D)}>
                <h3>Zone D</h3>
                <img src={showD ? Hand4_henna : Hand4} className='length-image-hand' alt=""/> 
              </Button>
            </div>

            <div className="zone-container">
              <Button onClick={() => zoneSelectedHandler(LENGTH_HANDS.C)}>
                <h3>Zone C</h3>
                <img src={showC ? Hand3_henna : Hand3} className='length-image-hand' alt=""/>
              </Button>
            </div>

            <div className="zone-container">
              <Button onClick={() => zoneSelectedHandler(LENGTH_HANDS.B)}>
                <h3>Zone B</h3>
                <img src={showB ? Hand2_henna : Hand2} className='length-image-hand' alt=""/>
              </Button>
            </div>

            <div className="zone-container">
              <Button onClick={() => zoneSelectedHandler(LENGTH_HANDS.A)}>
                <h3>Zone A</h3>
                <img src={showA ? Hand1_henna : Hand1} className='length-image-hand' alt=""/>
              </Button>
            </div>
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button sx={lightButtonStyle} disabled = {!selectedLength} onClick={(e) => save(e)}>
          SELECT ZONE {selectedLength}
      </Button>
    </DialogActions>
</Dialog>

  );
}

export default LengthHands;