import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserCredential } from "firebase/auth";
import { Order } from "./orderSlice";
import { STATUS } from "../../types";

// Define a type for the slice state
export interface Inquiry {
  id: string;
  name: string;
  email: string;
  hennaDate: number;
  weddingDate: number;
  location: string;
  order: Order;
  status: STATUS;
}
export interface State {
  inquiries: Array<Inquiry>;
  isBootstrapped: boolean;
  isAuthenticated?: boolean;
}

// Define the initial state using that type
const initialState: State = {
  isBootstrapped: false,
  inquiries: [],
  isAuthenticated: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsBootstrapped: (state, action: PayloadAction<boolean>) => {
      state.isBootstrapped = action.payload;
    },
    setAllInquiries: (state, action: PayloadAction<Array<Inquiry>>) => {
      state.inquiries = action.payload;
    },
    deleteInquiry: (state, action: PayloadAction<string>) => {
      state.inquiries = state.inquiries.filter((inquiry) => inquiry.id !== action.payload);
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setIsBootstrapped, setAllInquiries, setIsAuthenticated, deleteInquiry } = appSlice.actions;

export default appSlice.reducer;
