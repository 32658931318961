import Option from "./Option";
import Gauri from '../assets/images/bridal/gauri.jpg';
import Jodha from '../assets/images/bridal/jodha.jpg';
import { AMOUNT_FEET } from "../types";

type Props = {
  amountSelected: (selectedAmount: AMOUNT_FEET) => void;
}

function AmountFeet(props: Props) {
  return (
      <div className="options-container">
        <Option 
          headerText={AMOUNT_FEET.GAURI}
          imageUrl={Gauri}
          callback={() => props.amountSelected(AMOUNT_FEET.GAURI)}
        />

        <Option 
          headerText={AMOUNT_FEET.JODHA}
          imageUrl={Jodha}
          callback={() => props.amountSelected(AMOUNT_FEET.JODHA)}
        />
      </div>
  );
}

export default AmountFeet;