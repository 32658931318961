function NotFound() {
  return (
    <div className="bg">
      <div>
        <h1>404</h1>
        <p>Denne side kunne ikke findes :(</p>
      </div>
    </div>
  );
}

export default NotFound;