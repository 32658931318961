import { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TopContainer from '../components/TopContainer';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, NativeSelect, OutlinedInput, Select, SelectChangeEvent, SxProps } from '@mui/material';
import Login from '../components/Login';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { deleteInquiry, Inquiry, setAllInquiries, setIsAuthenticated } from '../features/order/appSlice';
import { formatDate, getEnumDisplayValue } from '../utils';
import ClearIcon from '@mui/icons-material/Clear';
import useConfirm from '../hooks/useConfirm';
import { dbDeleteInquiry, updateInquiry } from '../firebase';
import { Alert } from '@mui/lab';
import { STATUS } from '../types';

const rowStyle: SxProps = {
  backgroundColor: '#120c0e',

  ":hover": {
    cursor: 'pointer'
  }
}



const headerCell: SxProps = {
  color: '#A38671',
  fontWeight: '600',
  fontFamily: 'Lato-regular',
  borderBottom: '1px solid grey'
}

const Inquiries = () => {
  const navigate = useNavigate();
  const _inquiries = useAppSelector(state => state.app.inquiries);
  const isAuthenticated = useAppSelector(state => state.app.isAuthenticated);
  const dispatch = useDispatch();
  const auth = getAuth();
  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure?',
    'Are you sure you want to delete this inquiry?',
  )
  const [showError, setShowError] = useState(false);
  const [updatingInquiry, setUpdatingInquiry] = useState('')

  useEffect(() => {
    onAuthStateChanged(auth, (data) => {
      dispatch(setIsAuthenticated(!!data));
    })
  }, [])

  const onDeleteInquiryHandler = async(e: any, inquiryId: string) => {
    setShowError(false);
    e.stopPropagation();
    const confirmed = await confirmDelete()

    if(confirmed) {
      dbDeleteInquiry(inquiryId).then(() => {
        dispatch(deleteInquiry(inquiryId))
      }).catch(() => {
        setShowError(true);
        console.error(`Could not delete inquiry id ${inquiryId}.`);
      })
    } else {
      //Do nothing..
    }

  }

  const statusChangedHandler = (inquiryId: string, status: STATUS) => {
    setUpdatingInquiry(inquiryId)
    updateInquiry(inquiryId, status).then(() => {
      const updatedInquiries = inquiries.map(inquiry => {
        if(inquiry.id === inquiryId) {
          return {
            ...inquiry,
            status
          }
        } else {
          return inquiry
        }
      })

      dispatch(setAllInquiries(updatedInquiries))
    }).finally(() => {
      setUpdatingInquiry('')
    })
  }

  const inquiries = useMemo(() => {
    const sortedByDate = [..._inquiries].sort((a,b) => a.hennaDate - b.hennaDate);
    const incompleted = sortedByDate.filter(inq => inq.status !== STATUS.COMPLETED)
    const completed = sortedByDate.filter(inq => inq.status === STATUS.COMPLETED)

    return [...incompleted, ...completed]
  }, [_inquiries])

  const cellStyle = (status: STATUS) : SxProps =>  {

    return {
      color: status === STATUS.COMPLETED ? '#a386713b' : '#A38671',
      fontFamily: 'Lato-regular',
      borderBottom: '1px solid grey'
    }
  }


  return (
    <>
      {Dialog()}
      {showError && <Alert severity="error">Could not delete inquiry.</Alert>}

    {
      !isAuthenticated ?
      <Login />
      :
    <> 
    <TopContainer headerText='Inquiries' />
    <Box sx={{ width: '70%', marginTop: '30px' }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <TableBody>
              <TableRow sx={{backgroundColor: '#120c0e'}}>
                <TableCell align="left" sx={headerCell}>Name</TableCell>
                <TableCell align="center" sx={headerCell}>Email</TableCell>
                <TableCell align="center" sx={headerCell}>Location</TableCell>
                <TableCell align="center" sx={headerCell}>Henna date</TableCell>
                <TableCell align="center" sx={headerCell}>Wedding date</TableCell>
                <TableCell align="center" sx={headerCell}>Status</TableCell>
                <TableCell align="center" sx={headerCell}></TableCell>
              </TableRow>
              {inquiries.map((row: any) => {
                return (
                  <TableRow key={row.id} sx={{...rowStyle }} >
                      <TableCell align="left" onClick={() => navigate(`/inquiry/${row.id}`)} sx={cellStyle(row.status)}>{row.name}</TableCell>
                      <TableCell align="center" onClick={() => navigate(`/inquiry/${row.id}`)} sx={cellStyle(row.status)}>{row.email}</TableCell>
                      <TableCell align="center" onClick={() => navigate(`/inquiry/${row.id}`)} sx={cellStyle(row.status)}>{row.location}</TableCell>
                      <TableCell align="center" onClick={() => navigate(`/inquiry/${row.id}`)} sx={cellStyle(row.status)}>{formatDate(row.hennaDate)}</TableCell>
                      <TableCell align="center" onClick={() => navigate(`/inquiry/${row.id}`)} sx={cellStyle(row.status)}>
                          {formatDate(row.weddingDate)}
                      </TableCell>
                      <TableCell align="center" sx={{borderBottom: '1px solid grey'}} >
                        {
                          updatingInquiry !== row.id ?
                        <NativeSelect onChange={(e) => statusChangedHandler(row.id, e.target.value as STATUS)}
                        value={row.status ?? '-'}
                        sx={{color: row.status === STATUS.COMPLETED ? '#a386713b' : '#A38671', borderBottom: '0px solid grey', width: '140px', "& svg": { color: row.status === STATUS.COMPLETED ? '#a386713b' : '#A38671'}, "& select" : {textAlign: 'center'}}}
                          inputProps={{
                            name: 'status',
                            id: 'uncontrolled-native',
                          }}
                          >
                            {
                              Object.values(STATUS).map((value) => <option key={value} value={value}>{getEnumDisplayValue(value)}</option>)
                            }
                          </NativeSelect>
                          : 
                          <CircularProgress />
                        }
                        </TableCell>
                      <TableCell align="center" sx={cellStyle(row.status)}>
                        <Button onClick={e => onDeleteInquiryHandler(e, row.id)}>
                          <ClearIcon sx={{color: row.status === STATUS.COMPLETED ? '#a386713b' : '#A38671'}} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
    </>
    }

    </>
  );
}

export default Inquiries;