import { useEffect, useState } from "react";
import { LENGTH_FEET } from "../types";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { lightButtonStyle } from "../styles/theme";
import Button from '@mui/material/Button';
import Feet4 from '../assets/svg/feet/zone_d.png'
import Feet3 from '../assets/svg/feet/zone_c.png'
import Feet2 from '../assets/svg/feet/zone_b.png'
import Feet1 from '../assets/svg/feet/zone_a.png'
import Feet3_henna from '../assets/svg/feet/zone_c_henna.png'
import Feet2_henna from '../assets/svg/feet/zone_b_henna.png'
import Feet1_henna from '../assets/svg/feet/zone_a_henna.png'

type Props = {
  hasSelectedLengthCallback: (selectedLength: LENGTH_FEET) => void;
  isLengthDialogOpen: boolean;
  closeLengthDialog: () => void;
  previouslySelectedLength?: LENGTH_FEET;
}


function LengthFeets(props: Props) {

  const { closeLengthDialog, hasSelectedLengthCallback, isLengthDialogOpen, previouslySelectedLength } = props;

  const [selectedLength, setSelectedLength] = useState<LENGTH_FEET | undefined>(previouslySelectedLength);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [showC, setShowC] = useState(false);

  const onCloseDialog = () => {
    setSelectedLength(undefined);
    closeLengthDialog();
  }

  const save = (e:any) => {
    if (selectedLength) {
      hasSelectedLengthCallback(selectedLength);
      closeLengthDialog();
    }      
  }

  useEffect(() => {
    previouslySelectedLength && zoneSelectedHandler(previouslySelectedLength);
  }, [previouslySelectedLength])

  const zoneSelectedHandler = (zone: LENGTH_FEET ) => {    
    switch(zone) {
      case LENGTH_FEET.A:
        setShowC(false)
        setShowB(false)
        setShowA(true);
        setSelectedLength(LENGTH_FEET.A)
        break;
      case LENGTH_FEET.B:
        setShowC(false)
        setShowB(true)
        setShowA(true);
        setSelectedLength(LENGTH_FEET.B)
        break;
      case LENGTH_FEET.C:
        setShowC(true)
        setShowB(true)
        setShowA(true);
        setSelectedLength(LENGTH_FEET.C)
        break;
      default:
        // code block
    }
  }

  return (
    <Dialog onClose={() => onCloseDialog()} open={isLengthDialogOpen}>
    <DialogContent dividers>
      <div className='dialog-container'>
        <h1>Length</h1>
        <p>Please choose the required length of your design.</p>
          <br></br>
        <div className="length-image-container">
            <div className="zone-container">
              <Button disabled>
                <img src={Feet4} className='length-image-feet' alt=""/>
              </Button>
            </div>
    
            <div className="zone-container">
              <Button onClick={() => zoneSelectedHandler(LENGTH_FEET.C)}>
                <h3>Zone C</h3>
                <img src={showC ? Feet3_henna : Feet3} className='length-image-feet' alt=""/>
              </Button>
            </div>

            <div className="zone-container">
              <Button onClick={() => zoneSelectedHandler(LENGTH_FEET.B)}>
                <h3>Zone B</h3>
                <img src={showB ? Feet2_henna : Feet2} className='length-image-feet' alt=""/>
              </Button>
            </div>

            <div className="zone-container">
              <Button onClick={() => zoneSelectedHandler(LENGTH_FEET.A)}>
                <h3>Zone A</h3>
                <img src={showA ? Feet1_henna : Feet1} className='length-image-feet' alt=""/>
              </Button>
            </div>
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button sx={lightButtonStyle} disabled = {!selectedLength} onClick={(e) => save(e)}>
          SELECT ZONE {selectedLength}
      </Button>
    </DialogActions>
</Dialog>

  );
}

export default LengthFeets;