import Option from "./Option";
import Simple from '../assets/images/group/simple.jpg';
import Classic from '../assets/images/group/classic.jpg';
import { GROUP_CATEGORIES } from "../types";
import InfoModal from "./InfoModal";
import { useState } from "react";
import { classic, classicDescription, InspirationList, simple, simpleDescription } from "../db";

type Props = {
}

type InfoModalType = {
  header: string,
  description: string
  inspirationImages: InspirationList
}

function AmountGroup(props: Props) {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedCategoryInfo, setSelectedCategoryInfo] = useState<InfoModalType>();

  const openInfoModal = (category: GROUP_CATEGORIES) => {
    switch (category) {
      case GROUP_CATEGORIES.SIMPLE:
        setSelectedCategoryInfo(
          {
            header: 'Simple',
            description: simpleDescription,
            inspirationImages: simple
            
          }
        );
        break;
      case GROUP_CATEGORIES.CLASSIC:
        setSelectedCategoryInfo(
          {
            header: 'Classic',
            description: classicDescription,
            inspirationImages: classic
          }
        );
        break;
      default:
        console.error(`Sorry, category could not be determined.`);
    }
    setIsInfoModalOpen(true)
  }

  return (
    <>
    {
      (isInfoModalOpen && selectedCategoryInfo?.header && selectedCategoryInfo.description) &&
      
        <InfoModal
          header={selectedCategoryInfo?.header}
          description={selectedCategoryInfo?.description}
          isOpen={isInfoModalOpen}
          setIsOpen={isOpen => setIsInfoModalOpen(isOpen)}
          inspirationImages={selectedCategoryInfo.inspirationImages}
        />
    }
      <div className="group-options">
        <Option
          headerText={GROUP_CATEGORIES.SIMPLE}
          imageUrl={Simple}
          descriptionText='Delicate and minimalistic'
          callback={() => openInfoModal(GROUP_CATEGORIES.SIMPLE)}
        />

        <Option 
          headerText={GROUP_CATEGORIES.CLASSIC}
          imageUrl={Classic}
          descriptionText='Classic and medium'
          callback={() => openInfoModal(GROUP_CATEGORIES.CLASSIC)}
        />
        
      </div>
    </>

  );
}

export default AmountGroup;