import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { SxProps } from '@mui/material';
import { signIn } from '../firebase';
import { Alert } from '@mui/material';


export const textfieldStyle: SxProps = {

  '& label': {
    color: '#A38671', 
  },
  
  "& .MuiFilledInput-root": { 
    backgroundColor: 'transparent'
  },
  "& .MuiFilledInput-root.Mui-focused": { 
    backgroundColor: 'transparent'
  },
  input: {
    textAlign: 'center', 
    color: '#A38671', 
    fontSize: 17,
    fontFamily: 'Lato-regular',
    letterSpacing: '1px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#A38671',
    },
    '&:hover fieldset': {
      borderColor: '#A38671',
    }
  }
}

const Login = () => {

  const [showError, setShowError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setShowError(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (email && password) {
      signIn(email, password).catch(e => {
        console.error(e);
        setShowError(true);
      });
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      {showError && <Alert severity="error">Invalid email or password</Alert>}
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  sx={textfieldStyle}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  sx={textfieldStyle}
                  onChange={(e) => setPassword(e.target.value)}
                  />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Login;