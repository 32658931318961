import AboutTheArtistPageImage from '../assets/images/singlepage/about.jpg';
import art from '../assets/images/singlepage/art.jpg';
import art2 from '../assets/images/singlepage/art2.jpg';

const AboutTheArtPage = () => {

    return (
        <>          
            <div id='art' className="about-the-art-page-container">
                <div className="left-container">
                    <img className="about-the-art-page-image" src={AboutTheArtistPageImage} alt="" />
                </div>

                <div className="right-container">
                    <div className="about-page-content">
                        <h1>THE ART</h1>
                        <h2>TRADITIONAL ORGANIC HENNA</h2>

                        <p>
                        Henna is one of the safest forms of body art and has been used for centuries as a significant part in the wedding rituals across cultures. It is traditionally applied as intricate and contemporary designs to the hands and feet of women. The paste is made from dried and powdered leaves of the plant called Lawsonia Inermis which has natural staining properties. 
                            <br/>
                            <br/>
                            All henna paste used by me is handmade fresh using only natural ingredients. Over the years, I have been perfecting my henna paste recipe and found the best combination of organic ingredients that makes a paste which gives out the true color desired. I use 100% organic henna powder which is finely sifted and mixed with raw cane sugar, water and pure essential lavender oil. 
                            <br/>
                            <br/>
                            Natural henna stain darkens each day and leaves a beautiful mahogany-brown toned stain around 3-4th day. I will guide you throughout the whole process with aftercare tips as it plays an important role to achieve the perfect stain result.  
                        </p>

                        <div className="about-the-art-image-container">
                            <img src={art2} alt="" />
                            <img src={art} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
  
  export default AboutTheArtPage;