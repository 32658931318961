import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AMOUNT_FEET, AMOUNT_HANDS, GROUP_CATEGORIES, Image, LENGTH_FEET, LENGTH_HANDS } from '../../types';
import { isObjectEmpty } from '../../utils';

type BridalFeature = {
	amount: AMOUNT_HANDS | AMOUNT_FEET,
	inspiration: Image,
	length: LENGTH_HANDS | LENGTH_FEET
}

interface BridalType {
	inner?: BridalFeature
	outer?: BridalFeature
	feet?: BridalFeature
	note?: string
}

export interface GroupType {
	designCategory: GROUP_CATEGORIES,
	numberOfPeople: number,
	note?: string
}

// Define a type for the slice state
export interface Order {
	bridal?: BridalType,
	group1?: GroupType,
	group2?: GroupType,
  }
  
// Define the initial state using that type
const initialState: Order = {};

export const OrderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		updateBridal: (state, action: PayloadAction<BridalType>) => {
			state.bridal = action.payload
		},
		updateGroup1: (state, action: PayloadAction<GroupType>) => {
			state.group1 = action.payload
		},
		updateGroup2: (state, action: PayloadAction<GroupType>) => {
			state.group2 = action.payload
		},
		removeInner: (state) => {
			delete state.bridal?.inner;
			isObjectEmpty(state.bridal) && delete state.bridal
		},
		removeOuter: (state) => {
			delete state.bridal?.outer;
			isObjectEmpty(state.bridal) && delete state.bridal
		},
		removeFeet: (state) => {
			delete state.bridal?.feet;
			isObjectEmpty(state.bridal) && delete state.bridal
		},
		removeGroup1: (state) => {
			delete state.group1;
		},
		removeGroup2: (state) => {
			delete state.group2;
		},
		removeBridal: (state) => {
			delete state.bridal
		}
	}
});

export const { updateBridal, updateGroup1, updateGroup2, removeInner, removeOuter, removeFeet, removeGroup1, removeGroup2, removeBridal } = OrderSlice.actions;

export default OrderSlice.reducer;