import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { AMOUNT_HANDS, AMOUNT_FEET, Image } from '../types';
import { InspirationList } from '../db';
import { useState } from 'react';
import { lightButtonStyle } from '../styles/theme';


type Props = {
  isGridOpen: boolean
  selectedCategory: AMOUNT_HANDS | AMOUNT_FEET
  inspirationImages: InspirationList
  closeGrid: () => void
  hasSelectedInspirationImageCallback: (inspiration: Image) => void
  previouslySelectedInspirationImage?: Image
  description: string
}

const Grid = (props: Props) => {
  const { closeGrid, hasSelectedInspirationImageCallback, inspirationImages, isGridOpen, selectedCategory, previouslySelectedInspirationImage, description } = props;
  const [selectedImage, setSelectedImage] = useState<Image |undefined>(previouslySelectedInspirationImage);


  const save = (e:any) => {
    if (selectedImage) {
      hasSelectedInspirationImageCallback(selectedImage);
      closeGrid();
    }      
  }

  const imageSelected = (_image: Image) => {
    if(_image.id === selectedImage?.id) {
      setSelectedImage(undefined);
    } else {
      setSelectedImage(_image)
    }
  }

  const onCloseGrid = () => {
    closeGrid();
  }
  
  return (
      <Dialog onClose={() => onCloseGrid()} open={isGridOpen}>
          <DialogContent dividers>
          <div className='dialog-container'>
            <h1>{selectedCategory?.toUpperCase()}</h1>
            <p>{description}</p>
            <p>Please choose a design for inspiration that complements the style and elements that you are looking for.</p>
            
            <ImageList cols={3}>
              {inspirationImages.map((item) => (
                <ImageListItem style={ item.id === selectedImage?.id ? {border: '8px solid #866D59'} : {}} key={item.imageUrl}>
                    <div onClick={() => imageSelected(item)} className="image-container">
                      <img src={item.imageUrl} alt=''/>
                    </div>
                </ImageListItem>
              ))}
            </ImageList>
          </div>
          </DialogContent>
          <DialogActions>
            <Button sx={lightButtonStyle} disabled = {!selectedImage} onClick={(e) => save(e)}>
                SELECT DESIGN
            </Button>
          </DialogActions>
      </Dialog>
  );
}
;

export default Grid;