
import appReducer from './features/order/appSlice';
import stepsReducer from './features/order/stepsSlice';
import orderReducer from './features/order/orderSlice';
import userReducer from './features/order/userSlice';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
	reducer: {
		app: appReducer,
		order: orderReducer,
		steps: stepsReducer,
		user: userReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch