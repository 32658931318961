export type OptionType = {
    headerText: string
    imageUrl: string
    callback?: () => void
    descriptionText?: string
    size?: SIZE
}

export type Image = {
  id: string
  imageUrl: string
}

export enum SIZE {
  SMALL,
  MEDIUM,
  LARGE
}

export enum AMOUNT_HANDS {
  LEELA='Leela',
  RADHA='Radha',
  MAHARANI='Maharani'
}

export enum GROUP_CATEGORIES {
  SIMPLE='Simple',
  CLASSIC='Classic'
}

export enum AMOUNT_FEET {
  GAURI='Gauri',
  JODHA='Jodha',
}



export enum LENGTH_HANDS{
  A='A',
  B='B',
  C='C',
  D='D',
}

export enum LENGTH_FEET {
  A='A',
  B='B',
  C='C',
}

export enum STATUS {
  NEW='NEW',
  AWAITING='AWAITING',
  CONFIRMED='CONFIRMED',
  PAYMENT='PAYMENT',
  COMPLETED='COMPLETED',
  CANCELLED='CANCELLED'
}