import { Button } from "@mui/material";
import { OptionType } from "../types";



function Option(props: OptionType) {

  return (
    <>
      <div className="option-container">
      <Button onClick={() => props.callback && props.callback()} className="option">
        <div className="header-container">
          <h4>{props.headerText}</h4>
        </div>
        <img src={props.imageUrl} alt=""/>
        <p>{props.descriptionText}</p>
      </Button>
      </div>
      </>
  );
}

export default Option;

