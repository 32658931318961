import Option from "./Option";
import Leela_inner from '../assets/images/bridal/leela_inner.jpg';
import Leela_outer from '../assets/images/bridal/leela_outer.jpg';
import Radha_inner from '../assets/images/bridal/radha_inner.jpg';
import Radha_outer from '../assets/images/bridal/radha_outer.jpg';
import Maharani_inner from '../assets/images/bridal/maharani_inner.jpg';
import Maharani_outer from '../assets/images/bridal/maharani_outer.jpg';
import { AMOUNT_HANDS } from "../types";
import { leela_inner, leela_outer } from "../db";

type Props = {
  amountSelected: (selectedAmount: AMOUNT_HANDS) => void;
  innerOrOuter: 'INNER' | 'OUTER'
}

function AmountHands(props: Props) {
  return (
      <div className="options-container">
        <Option 
          headerText={AMOUNT_HANDS.LEELA}
          imageUrl={props.innerOrOuter === 'INNER' ? Leela_inner : Leela_outer}
          callback={() => props.amountSelected(AMOUNT_HANDS.LEELA)}
        />

        <Option 
          headerText={AMOUNT_HANDS.RADHA}
          imageUrl={props.innerOrOuter === 'INNER' ? Radha_inner : Radha_outer}
          callback={() => props.amountSelected(AMOUNT_HANDS.RADHA)}
        />
        
        <Option 
          headerText={AMOUNT_HANDS.MAHARANI}
          imageUrl={props.innerOrOuter === 'INNER' ? Maharani_inner : Maharani_outer}
          callback={() => props.amountSelected(AMOUNT_HANDS.MAHARANI)}
        />
      </div>
  );
}

export default AmountHands;